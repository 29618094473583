import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BeritaModel } from './../../../model/berita-model';
import { ClientService } from '../../client.service';
import { environment } from 'src/environments/environment';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';

@Component({
  selector: 'app-berita',
  templateUrl: './berita.component.html',
  styleUrls: ['./berita.component.css']
})
export class BeritaComponent implements OnInit {

  tabTitle = "DPTR-DIY | Pub-Berita";
  listBerita: BeritaModel[] = [];
  totalRecords: Number;
  page: Number = 1;
  judul: string = '';
  carijudul: string = '';
  baseUrlImg = environment.baseApi+"resources/berita-img/"

  constructor(
    private titleService: Title,
    private service:ClientService,
    private activatedRoute:ActivatedRoute,
    private alert:Alert,
    private loading:Loading
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.tabTitle)
    this.loading.show()
    this.service.getTampilAllBerita().subscribe(
      data=>{
        this.loading.hide()
        this.listBerita = data;
        this.totalRecords = Object.keys(this.listBerita).length;
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Data')
        console.log(err)
      }
    )
  }

  cari() {
    if (this.carijudul == '') {
      this.ngOnInit()
    } else {
      this.listBerita = this.listBerita.filter(cariresult => {
        return cariresult.judul.toUpperCase().match(this.carijudul.toUpperCase());
      });
      this.page = 1;
      this.totalRecords = Object.keys(this.listBerita).length;
    }
  }

}

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="30" [hidden] = "node.role != token.userrole">
      <button  mat-button [routerLinkActive]="['background-highlight']" [routerLink]="node.routeLink" class="btn-menu" (click)="activeNode = node" [ngClass]="{ 'background-highlight': activeNode === node }">
        <mat-icon class="type-icon" [attr.aria-label]="node.icon">
          {{node.icon}}
        </mat-icon>
        {{node.name}}
      </button>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [hidden] = "node.role != token.userrole" >
      <button  mat-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" class="btn-menu" >
        <mat-icon class="type-icon" [attr.aria-label]="node.icon">
          {{node.icon}}
        </mat-icon>
        {{node.name}}
        <mat-icon style="float: right;margin-top: 6px;">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
  </mat-tree-node>
</mat-tree>

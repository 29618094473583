import { Component, AfterViewInit, ViewChild } from '@angular/core';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { Alert } from 'src/app/shared/Alert';
import { InputBeritaService } from './input-berita.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Loading } from 'src/app/shared/loading';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-input-berita',
  templateUrl: './input-berita.component.html',
  styleUrls: ['./input-berita.component.css']
})
export class InputBeritaComponent implements AfterViewInit {

  constructor(
    private service:InputBeritaService,
    private dialog:MatDialog,
    private alert:Alert,
    private auth:AuthService,
    private loading:Loading
    ) { }
  displayedColumns: string[] = ['no', 'penulis','editor', 'judul', 'status','tgl_upload','aksi'];
  data ;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  editorModules = {
    toolbar: [
       ['bold', 'italic', 'underline', 'strike'],
       [{'list': 'ordered'}, {'list': 'bullet'}],
       [{'script': 'sub'}, {'script': 'super'}],
       [{'indent': '-1'}, {'indent': '+1'}],
       [{'header': [1, 2, 3, 4, 5, 6, false]}],
       [{'color': []}, {'background':[]}],
       [{'align': []}],
    ]
  }

  form = {
    type:'created',
    oldFileName:'',
    statusBerita:'draft',
    penulis_id:this.auth.getUserLoginId(),
    id:'',
    judul:{
      value:'',
      error:''
    },
    isi:{
      value:'',
      error:''
    },
    image:{
      title:'Default-mage',
      url:'../../../assets/image/default-img-upload.jpg',
      label:'Default-mage'
    }
  }
  file:File
  status

  ngAfterViewInit(): void {
    this.getData();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(template){
      const dialogConfig = new MatDialogConfig();
      dialogConfig['disableClose'] = true
      dialogConfig['maxHeight'] = '100vh'
      dialogConfig['width'] = '1300px'

      this.dialog.open(template, dialogConfig);
  }

  onSelectFile(e){
    if(e.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event:any) => {
        this.form.image.url = event.target.result;
      }
      this.form.image.url = e.target.files[0].name;
      this.file = e.target.files[0];
      this.form.image.label= e.target.files[0].name;
    }
  }

  getData(){
    this.loading.show()
    this.service.getBerita().subscribe(
      res =>{
        this.loading.hide();
        this.data = res
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err=>{
        this.loading.hide();
        this.alert.error("Gagal Mendapatakan Data")
      }
    )
  }

  onSubmit(type){
    let formData = new FormData();
    let date = new Date();
    let dateTime = date.getTime();
    formData.append('fileUpload',this.file);
    // if (this.file) {
    //   let namaFile = this.file.name.substring(0, 4);
    //   formData.append('fileName',namaFile+dateTime)
    //   formData.append('fileUpload', this.file,this.file.name);
    // }
    if (!this.file) {
      return this.alert.error("Gambar Harus di Isi")
    }
    let namaFile = this.file.name.substring(0, 4);
    formData.append('fileName',namaFile+dateTime)
    formData.append('fileUpload', this.file,this.file.name);

    formData.append('judul',this.form.judul.value)
    formData.append('isi',this.form.isi.value)
    formData.append('typeForm',this.form.type)
    formData.append('id',this.form.id)
    formData.append('OldFileName',this.form.oldFileName)
    formData.append('status',type)
    formData.append('penulis_id',this.form.penulis_id)

    this.service.insertBerita(formData).subscribe(
      res=>{ 
        this.status = res
        this.alert.success(this.status.message)
        setTimeout(() => {
          location.reload();
        }, 200);
      },
      err=>{
        this.alert.error(err.error.message)
        console.log(err.error);
      }
    );
  }

  onEdit(data){
    this.form.type = 'updated'
    this.form.judul.value= data.judul
    this.form.isi.value = data.isiberita
    this.form.oldFileName=data.dokumentasi
    this.form.statusBerita = data.status
    this.form.id = data.id
    this.form.image.url = environment.baseApi+"resources/berita-img/"+data.dokumentasi
    this.form.image.title =data.dokumentasi
    this.form.image.label =data.dokumentasi
  }

  onUpdate(type){
    let formData = new FormData();
    let date = new Date();
    let dateTime = date.getTime();
    formData.append('fileUpload',this.file);
    formData.append('fileName',this.form.oldFileName)
    let namaFile
    // cek apakah gambar sudah tersedia
    switch (true) {
      case type=="draft" && !this.file:
          return this.alert.error('Gambar Tidah Boleh Kosong');
        break;
        case type=="draft" && this.file != null:
          namaFile = this.file.name.substring(0, 4);
          formData.append('fileName',namaFile+dateTime)
          formData.append('fileUpload', this.file,this.file.name);
          break;
        case type!="draft" && this.file != null:
          namaFile = this.file.name.substring(0, 4);
          formData.append('fileName',namaFile+dateTime)
          formData.append('fileUpload', this.file,this.file.name);
        break;
        case type!="draft" && !this.file && this.form.id =='':
          return this.alert.error('Gambar Tidah Boleh Kosong');
        break;
      default:
        // return this.alert.error('Terjadi Kesalahan Coba Lagi');
        break;
    }
    formData.append('judul',this.form.judul.value)
    formData.append('isi',this.form.isi.value)
    formData.append('typeForm',this.form.type)
    formData.append('id',this.form.id)
    formData.append('OldFileName',this.form.oldFileName)
    formData.append('status',type)
    formData.append('penulis_id',this.form.penulis_id)

    this.loading.show()
    this.service.updateBerita(formData).subscribe(
      res=>{ 
        this.status = res
        this.alert.success(this.status.message)
        setTimeout(() => {
          location.reload();
        }, 200);
        this.loading.hide()
      },
      err=>{
        this.loading.hide()
        this.alert.error(err.error.message)
        console.log(err.error);
      }
    );
  }

  onDelete(id){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: 'warning', //warning,danger,success
        title: 'Warning',
        message: 'Anda Yakin Ingin Menghapus Data Ini'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.loading.show()
        this.service.delete(id).subscribe(
          res=>{
            this.alert.success('Data Berhasil di Hapus');
            this.getData();
            this.loading.hide()
          },
          err=>{
            this.alert.success('Data Gagal di Hapus');
            console.log(err)
            this.loading.hide()
          }
        )
      }
    });
  }

}

import { AdminService } from './admin.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { AdminpagesRoutingModule } from './adminpages-routing.module';

import { AdminpagesComponent } from './adminpages.component';
import { AdmfooterComponent } from './ap-templates/admfooter/admfooter.component';
import { AdmheaderComponent } from './ap-templates/admheader/admheader.component';
import { AddberitaDialogComponent } from './admdialogs/addberita-dialog/addberita-dialog.component';
import { AdmcarouselComponent } from './admcarousel/admcarousel.component';
import { SharedModule } from '../shared/shared.module';
import { AdmprofilComponent } from './admprofil/admprofil.component';
import { AdmvisimisiComponent } from './admprofil/admvisimisi/admvisimisi.component';
import { AdmtupoksiComponent } from './admprofil/admtupoksi/admtupoksi.component';
import { AdmsotkComponent } from './admprofil/admsotk/admsotk.component';
import { DataTablesModule } from 'angular-datatables';
import { ListberitaDialogComponent } from './admdialogs/listberita-dialog/listberita-dialog.component';
import { MenuComponent } from './ap-templates/menu/menu.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { UserComponent } from './user/user.component';
import { BeritaComponent } from './berita/berita.component';
import { BerkalaComponent } from './ppid/berkala/berkala.component';
import { SertamertaComponent } from './ppid/sertamerta/sertamerta.component';
import { TiapsaatComponent } from './ppid/tiapsaat/tiapsaat.component';
import { RestraComponent } from './publikasi/restra/restra.component';
import { LapKinerjaComponent } from './publikasi/lap-kinerja/lap-kinerja.component';
import { LapKeuanganComponent } from './publikasi/lap-keuangan/lap-keuangan.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsercrudDialogsComponent } from './admdialogs/usercrud-dialogs/usercrud-dialogs.component';
import { PeraturanComponent } from './publikasi/peraturan/peraturan.component';



@NgModule({
  declarations: [
    AdminpagesComponent,
    AdmfooterComponent,
    AdmheaderComponent,
    AddberitaDialogComponent,
    AdmcarouselComponent,
    AdmprofilComponent,
    AdmvisimisiComponent,
    AdmtupoksiComponent,
    AdmsotkComponent,
    ListberitaDialogComponent,
    MenuComponent,
    UserComponent,
    BeritaComponent,
    BerkalaComponent,
    SertamertaComponent,
    TiapsaatComponent,
    RestraComponent,
    LapKinerjaComponent,
    LapKeuanganComponent,
    DashboardComponent,
    UsercrudDialogsComponent,
    PeraturanComponent
  ],
  imports: [
    CommonModule,
    AdminpagesRoutingModule,
    MaterialModule,
    QuillModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    DataTablesModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [AdminService]
})
export class AdminpagesModule {}

<!-- <p>lhkpn works!</p> -->
<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card"  style="width: 80%;">
                <div class="card-header h5">
                    LHKPN Pejabat
                </div>
                <div class="card-body">
                    <p style="text-align: justify; font-size: medium; line-height: 1.5rem;">
                        Setiap tahun pejabat struktural dan pejabat fungsional tertentu di lingkungan Dinas Pertanahan dan Tata Ruang Daerah Istiemewa Yogyakarta wajib melaporkan harta kekayaannya. Informasi terkait Laporan Harta Kekayaan Penyelenggara Negara (LHKPN) dapat diakses melalui aplikasi e-Announcement e-LHKPN dengan alamat <span><a href="https://elhkpn.kpk.go.id/portal/user/login#announ" target="_blank">https://elhkpn.kpk.go.id/portal/user/login#announ</a></span> yang dikembangkan Komisi Pemberantasan Korupsi Republik Indonesia (KPK RI). Berikut adalah LHKPN Ka. Dispertaru DIY:
                    </p>
                    <iframe [src]="srcURL" frameborder="2" class="w-100" style="height: 680px;"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
    </div>
</div>

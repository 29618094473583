<!-- <p>detail-berita works!</p> -->
<div class="container mt-3 mb-5">
    <div class="row justify-content-center">
        <div class="col-md-9 mb-3">
            <div class="card">
                <div *ngIf="detailBerita">
                    <img class="card-img-top thumbnail" [attr.src]="baseUrlImg+detailBerita.dokumentasi" alt="Foto Kegiatan" style="height: 500px;">
                    <div class="card-body">
                        <p class="card-title judul" style="font-weight: 500; font-size: large; text-align: justify; line-height: 1.3;">{{detailBerita.judul | uppercase}}</p>
                        <div class="card-text ql-editor" [innerHTML]="detailBerita.isiberita | safehtml" style="text-align: justify; font-weight: 370;line-height: 1.8;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BeritaModel } from '../model/berita-model';
import { CarouselModel } from '../model/carousel-model';
import { Observable, Subject } from 'rxjs';
import { BeritaInterface } from '../model/berita-interface';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  showPdfFile: boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  private autoUpdate = new Subject<void>();
  get onUpdate(): any {
    return this.autoUpdate;
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + btoa('public:public'),
      'USR-KEY': 'dispertaru'
    }),
  };

  getCarousel(): Observable<CarouselModel[]> {
    const serverURL = environment.baseApi + 'beranda/carousel';
    return this.http.get<CarouselModel[]>(serverURL, this.httpOptions);
  }

  getTampilBerita(): Observable<BeritaModel[]> {
    const serverURL = environment.baseApi + 'beranda/berita';
    return this.http.get<BeritaModel[]>(serverURL, this.httpOptions);
  }

  getVisiMisi(): any{
    const serverURL = environment.baseApi + 'profil/visimisi';
    return this.http.get(serverURL, this.httpOptions);
  }

  getTupoksi(): any{
    const serverURL = environment.baseApi + 'profil/tupoksi';
    return this.http.get(serverURL, this.httpOptions);
  }

  getSotk(): any{
    const serverURL = environment.baseApi + 'profil/sotk';
    return this.http.get(serverURL, this.httpOptions);
  }

  getPejabat(): any{
    const serverURL = environment.baseApi + 'profil/pejabat';
    return this.http.get(serverURL, this.httpOptions);
  }

  getBerkala(): any{
    const serverURL = environment.baseApi + 'ppid/berkala';
    return this.http.get(serverURL, this.httpOptions);
  }

  sertamerta(): any{
    const serverURL = environment.baseApi + 'ppid/sertamerta/public';
    return this.http.get(serverURL, this.httpOptions);
  }

  tiapSaat(): any{
    const serverURL = environment.baseApi + 'ppid/tiapsaat';
    return this.http.get(serverURL, this.httpOptions);
  }

  laporan(type): any{
    const serverURL = environment.baseApi + 'laporan/publikasi/' + type;
    return this.http.get(serverURL, this.httpOptions);
  }

  getTampilAllBerita(): Observable<BeritaModel[]> {
    const serverURL = environment.baseApi + 'berita/admin_berita/active';
    return this.http.get<BeritaModel[]>(serverURL, this.httpOptions);
  }

  getDetailBerita(beritaId): Observable<BeritaInterface> {
    const serverURL = environment.baseApi + 'berita/beritadetail/' + beritaId;
    return this.http.get<BeritaInterface>(serverURL, this.httpOptions);
  }

  getPenyesuaianPTK(): Observable<any> {
    const headers = {'Content-Type': 'application/json'};
    const httpParams: HttpParamsOptions = {
      fromObject: {sender: 'webdispertaru'}
    } as HttpParamsOptions;
    const options: any = { params: new HttpParams (httpParams), headers };
    // const serverURL = 'http://localhost/keistimewaan/webdptr'; //used laragon server in local
    const serverURL = 'https://intantaruberinfo.jogjaprov.go.id/keistimewaan/webdptr';
    // const serverURL = environment.extApi;
    // return this.http.get<any>(serverURL, options);
    return this.http.get<any>(serverURL, options).pipe(
      tap(() => {
         this.onUpdate.next();
      })
    );
  }

  getSosmed(): Observable<any> {
    const serverURL = environment.baseApi + 'sosmed';
    return this.http.get<any>(serverURL, this.httpOptions);
  }

  // getPdfFile(filename): Observable<any> {
  //   const serverURL = environment.baseApi + 'pdfviewer' + filename;
  //   return this.http.get<any>(serverURL, this.httpOptions);
  // }

  // getFile(fileName: string): Observable<any> {
  //   const url = this.apiUrl + fileName;
  //   return this.http.get(url, { responseType: 'blob' });
  // }

  getPdfFile(filename: string): Observable<Blob> {
    // console.log(filename);
    const serverURL = environment.baseApi + 'pdfviewer';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('public:public'),
        'USR-KEY': 'dispertaru'
      }),
      responseType: 'blob' as 'json'
    };
    const data = { filename: filename };

    return this.http.post<Blob>(serverURL, data, httpOptions);
  }

  generatePdfFile(filename:any){
    const serverURL = environment.baseApi + 'pdfviewer';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('public:public'),
        'USR-KEY': 'dispertaru'
      }),
    };

    // return this.http.get('https://localhost:7118/Invoice/generatepdf?InvoiceNo='+invoiceno,{observe:'response',responseType:'blob'});
    return this.http.get(serverURL + '?filename=' + filename,
      {
        headers: httpOptions.headers,
        observe:'response',
        responseType:'blob'
      }
    );
  }

  getBinaryData(filename: any): Observable<ArrayBuffer> {
    const serverURL = environment.baseApi + 'pdfviewer';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('public:public'),
        'USR-KEY': 'dispertaru'
      }),
    };
    return this.http.get(serverURL + '?filename=' + filename, { headers: httpOptions.headers, responseType: 'arraybuffer' });
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-lhkpn',
   templateUrl: './lhkpn.component.html',
   styleUrls: ['./lhkpn.component.css']
})
export class LhkpnComponent implements OnInit {

   tabTitle = 'DPTR-DIY | LHKPN';
   srcURL: SafeResourceUrl;

   constructor(private titleService: Title, private sanitizer: DomSanitizer) {}

   ngOnInit(): void {
      this.titleService.setTitle(this.tabTitle);
      const fileBaseURL = environment.baseApi
      const url = fileBaseURL + 'resources/file/laporan/lhkpn/LHKPN_Kadispertaru.pdf#zoom=55';

      // Sanitize the URL to make it safe:
      this.srcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }

}

export const environment = {
  production: true,
  baseApi : 'https://dispertaru.jogjaprov.go.id/api/',
  extApi : 'https://intantaruberinfo.jogjaprov.go.id/keistimewaan/webdptr',
  username: 'public',
  password: 'public'

};

export const baseUrl = 'https://dispertaru.jogjaprov.go.id/';
// export const baseUrl = 'http://localhost/web-dptr2/';
// export const baseUrl = "https://dispertaru.jogjaprov.go.id/new";
// export const baseUrl = "https://imron-apps.com/web-ptrdiy";


<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>Seting-Carausel</span>
</div>
<div class="card-body mat-elevation-z8" style="height: 75vh;">
    <div class="row">
        <div class="col-sm-12">
            <div class="row mb-3">
                <div class="col-md-5">
                    <mat-form-field appearance="" style="width: 100%;" class="mb-3">
                        <mat-label>Nomor Carousel</mat-label>
                        <mat-select name="carSelect" (ngModelChange)="getDetailCarousel($event)" [(ngModel)] = "form.id">
                            <mat-option *ngFor="let carNumber of carNumbers" [value]="carNumber.id">
                            {{'Carousel ke-'+carNumber.id}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
    
                    <div *ngIf="selectedValue">
                        <mat-form-field appearance="" style="width: 100%;" class="mb-3">
                            <mat-label>Judul</mat-label>
                            <input matInput name="carJudul" [(ngModel)]="form.judul">
                        </mat-form-field>
                        <mat-form-field appearance="" style="width: 100%;">
                            <mat-label>Deskripsi</mat-label>
                            <textarea matInput name="carDeskripsi" [(ngModel)]="form.deskripsi"></textarea>
                        </mat-form-field>
                        <button mat-flat-button color="primary" type="submit" (click)="postCarData()" class="mt-3">UPDATE</button>
                    </div>
                </div>
                <div class="col-md-7">
                    <div *ngIf="selectedValue">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="custom-file">
                                    <input type="file" name="fotoCar" accept="image/*" class="custom-file-input" (change)="selectFile($event)" ngModel>
                                    <input type="hidden" name="fotoCarFileName" class="custom-file-input" [(ngModel)]="imgCarLabel">
                                    <label class="custom-file-label" for="input-img" [innerHTML]="imgCarLabel"></label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="mt-3 text-center">
                                    <img [src]="urlCarImg" class="img-fluid" style="height: 200px; max-height: 200px;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
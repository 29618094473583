import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class KontributorGuard implements CanActivate {

  constructor(public auth:AuthService,public router:Router){}

  canActivate(){
    if (localStorage.getItem("XRF-DAT") && localStorage.getItem("USR-Ro") == "Kontributor") {
      return true
     } else {
       this.router.navigate(['/']);
       return false;
     }
  }
  
}

import { UserRegisterModel } from './../../../model/user-register-model';
import { AdminService } from './../../admin.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Loading } from 'src/app/shared/loading';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-usercrud-dialogs',
  templateUrl: './usercrud-dialogs.component.html',
  styleUrls: ['./usercrud-dialogs.component.css']
})


export class UsercrudDialogsComponent implements OnInit {

   userRegister:UserRegisterModel = new UserRegisterModel();
   // myRegisterForm:FormGroup = this.adminService.registerForm;
   myRegisterForm:FormGroup;
   selectedBidang:string = '';
   listsubbidang:{};
   apiRespStatus:any='';
   apiRespMsg:any='';
   sliderChecked=1;
   isExisted;
   email=''
   phone=''

   // constructor(private adminService:AdminService) { }
   constructor (
      private adminService:AdminService, 
      @Inject(MAT_DIALOG_DATA) public data:UserRegisterModel,
      public dialogRef: MatDialogRef<UsercrudDialogsComponent>, 
      private formBuilder:FormBuilder,
      public loading: Loading,
      public snackBar:MatSnackBar
      
      ) { 
      this.myRegisterForm = formBuilder.group({
         id: new FormControl(''),
         nama: new FormControl('', Validators.required),
         bidang: new FormControl('', Validators.required),
         subBidang: new FormControl('', Validators.required),
         password: new FormControl('', [Validators.required, Validators.minLength(6)]),
         confirmPassword: new FormControl('', [Validators.required]),
         email: new FormControl('', [Validators.required,Validators.email]),
         phone: new FormControl('', [Validators.required]),
         userRole: new FormControl('Kontributor'),
         // isActive: new FormControl(1)
         isActive: new FormControl(true)
      },{
         validators: this.matchingPassword('password', 'confirmPassword')
      });
   }

   initializeRegisterForm(){
      this.myRegisterForm.setValue({
         id:'',
         nama:'',
         bidang:'',
         subBidang:'',
         password:'',
         confirmPassword:'',
         email:'',
         phone:'',
         userRole:'Kontributor',
         // isActive:1
         isActive:true
      });
   }
   
   populateForm(rowData){
      // console.log(rowData);

      this.myRegisterForm.setValue({
         id:rowData.id,
         nama:rowData.nama,
         bidang:rowData.bidang,
         subBidang:rowData.subbidang,
         password:rowData.password,
         confirmPassword:rowData.password,
         email:rowData.email,
         phone:rowData.phone,
         userRole:rowData.userrole,
         // isActive:rowData.isactive
         isActive:JSON.parse(rowData.isactive)//converting from string to bool value
      });
      // console.log(this.myForm);
      // console.log(this.myForm.isActive);
   }

   specifyForm(rowData){
      // if this.data (from MAT_DIALOG_DATA) is not null
      if (this.isExisted) {
         this.populateForm(rowData);
         this.onBidangChange(this.data.bidang);
      } else {
         this.initializeRegisterForm();
      }
   }

   get myForm() {
      return this.myRegisterForm.controls;
      // return this.adminService.registerForm.controls;
   }

   ngOnInit(): void {
      // console.log(this.data)
      this.isExisted = this.data;
      this.specifyForm(this.data);
      // console.log(this.isExisted);
   }
   
   onBidangChange(val){
      // console.log(val);
      if(val == 'Sekretariat') {
         this.listsubbidang=['Umum', 'Program', 'Keuangan'];
      } else if(val == 'PPP') {
         this.listsubbidang=['Penatausahaan Pertanahan', 'Pengendalian Pertanahan'];
      } else if(val == 'PPPPP') {
         this.listsubbidang=['Administrasi Pemanfaatan', 'Penanganan Permasalahan'];
      } else if(val == 'TURBIN') {
         this.listsubbidang=['Pengaturan Tata Ruang', 'Pembinaan Tata Ruang'];
      } else if(val == 'LAKWAS') {
         this.listsubbidang=['Pelaksanaan Tata Ruang', 'Pengawasan Tata Ruang'];
      }
      // console.log(val)
   }

   onSubbidangChange(val){
      // console.log(val);
   }

   onActiveSliderChange(e) {
      console.log(e);
      // console.log(e.checked);
      // e.checked ? this.myRegisterForm.controls['isActive'].setValue(1) : this.myRegisterForm.controls['isActive'].setValue(0);
      // console.log (this.myRegisterForm.controls['isActive'].value);
      
   }

   matchingPassword(pwd:string, confirmPwd:string){
      return (formGroup:FormGroup) => {
         const pwdControl = formGroup.controls[pwd];
         const confirmPwdControl = formGroup.controls[confirmPwd];
         if (pwdControl.value !== confirmPwdControl.value) {
            confirmPwdControl.setErrors({matchingPassword: true});
         } else {
            confirmPwdControl.setErrors(null);
         }
      }
   }

   onSubmit(){
      this.loading.show()
      if(this.isExisted) {
         this.adminService.updateUser(this.myRegisterForm.value).subscribe(
            resp=>{
            this.apiRespMsg = resp['msg'];
            this.apiRespStatus = resp['status'];
            this.dialogRef.close();
            this.loading.hide()
            this.snackBar.open('Data Berhasil di SImpan',"X",{
               duration: 5000,
               panelClass:'berhasil'
            });
            setTimeout(function(){location.reload(); }, 1000);
         },
         error => {
            this.snackBar.open('Data Gagal di SImpan',"X",{
               duration: 5000,
               panelClass:'gagal'
            });
            this.loading.hide()
            console.log(error)
          }, 
         );
      } else {
         this.adminService.addNewUser(this.myRegisterForm.value).subscribe(
            resp=>{
               this.apiRespMsg = resp['msg'];
               this.apiRespStatus = resp['status'];
               this.snackBar.open('Data Berhasil di SImpan',"X",{
                  duration: 5000,
                  panelClass:'berhasil'
               });
               this.dialogRef.close();
               this.loading.hide()
               setTimeout(function(){location.reload(); }, 1000);
            },
            error => {
               this.snackBar.open('Data Gagal di SImpan',"X",{
                  duration: 5000,
                  panelClass:'gagal'
               });
               this.loading.hide()
               console.log(error)
             },
         );
      }
   }

   onClear(){
      this.myRegisterForm.reset();
      this.initializeRegisterForm();
      // this.adminService.registerForm.reset();
      // this.adminService.initializeRegisterForm();
   }

}

import { AdmsotkComponent } from './admprofil/admsotk/admsotk.component';
import { AdmtupoksiComponent } from './admprofil/admtupoksi/admtupoksi.component';
import { AdmvisimisiComponent } from './admprofil/admvisimisi/admvisimisi.component';
import { AdmcarouselComponent } from './admcarousel/admcarousel.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminpagesComponent } from './adminpages.component';
import { AuthguardGuard } from '../authguard.guard';
import { UserComponent } from './user/user.component';
import { BeritaComponent } from './berita/berita.component';
import { BerkalaComponent } from './ppid/berkala/berkala.component';
import { SertamertaComponent } from './ppid/sertamerta/sertamerta.component';
import { TiapsaatComponent } from './ppid/tiapsaat/tiapsaat.component';
import { LapKinerjaComponent } from './publikasi/lap-kinerja/lap-kinerja.component';
import { LapKeuanganComponent } from './publikasi/lap-keuangan/lap-keuangan.component';
import { RestraComponent } from './publikasi/restra/restra.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PeraturanComponent } from './publikasi/peraturan/peraturan.component';

const routes: Routes = [
  {
    path: '',
    component: AdminpagesComponent,
    canActivate: [AuthguardGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'admcarousel', component: AdmcarouselComponent },
      { path: 'admvisimisi', component: AdmvisimisiComponent },
      { path: 'admtupoksi', component: AdmtupoksiComponent },
      { path: 'admsotk', component: AdmsotkComponent },
      { path: 'berkala', component: BerkalaComponent },
      { path: 'tiapsaat', component: TiapsaatComponent },
      { path: 'sertamerta', component: SertamertaComponent },
      { path: 'laporan_kinerja', component: LapKinerjaComponent },
      { path: 'laporan_keuangan', component: LapKeuanganComponent },
      { path: 'peraturan', component: PeraturanComponent },
      { path: 'restra', component: RestraComponent },
      { path: 'users', component: UserComponent },
      { path: 'berita', component: BeritaComponent }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminpagesRoutingModule { }

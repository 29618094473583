<div class="container">
    <div class="row">
        <h2 class="mt-3">RENSTRA DAN RENJA</h2>
        <div class="col-sm-12">
            <div class="accordion" id="accordionExample" *ngFor="let dat of data">
                <div class="card mb-2">
                    <div class="card-header" [attr.id]="'heding'+dat.id" data-toggle="collapse" [attr.data-target]="'#colapse'+dat.id" aria-expanded="true" [attr.aria-controls]="'colapse'+dat.id">
                        <h3 class="mb-0 mt-2 ml-2">
                            <b>{{dat.title}}</b>
                        </h3>
                    </div>
              
                    <div [attr.id]="'colapse'+dat.id" class="collapse" [attr.aria-labelledby]="'heding'+dat.id" data-parent="#accordionExample">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-11">
                                    <div class="ql-editor" [innerHTML]="dat.deskripsi | safehtml"></div>
                                </div>
                                <div class="col-sm-1" *ngIf = "dat.file">
                                    <a [attr.href]="baseFileUrl+dat.file" target="blank">
                                        <button class="btn btm-block btn-primary"><mat-icon>download</mat-icon></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

import { QuillModule } from 'ngx-quill';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientpagesComponent } from './clientpages.component';
import { BerandaComponent } from './beranda/beranda.component';
import { PpidComponent } from './ppid/ppid.component';
import { PublikasiComponent } from './publikasi/publikasi.component';
import { CpHeaderComponent } from './cp-templates/cp-header/cp-header.component';
import { CpFooterComponent } from './cp-templates/cp-footer/cp-footer.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { CpBarheaderComponent } from './cp-templates/cp-barheader/cp-barheader.component';
import { VisimisiComponent } from './profil/visimisi/visimisi.component';
import { TupoksiComponent } from './profil/tupoksi/tupoksi.component';
import { SotkComponent } from './profil/sotk/sotk.component';
import { InfoBerkalaComponent } from './ppid/info-berkala/info-berkala.component';
import { InfoSertamertaComponent } from './ppid/info-sertamerta/info-sertamerta.component';
import { InfoTiapsaatComponent } from './ppid/info-tiapsaat/info-tiapsaat.component';
import { RenstraComponent } from './publikasi/renstra/renstra.component';
import { BeritaComponent } from './publikasi/berita/berita.component';
import { LapKinerjaComponent } from './publikasi/lap-kinerja/lap-kinerja.component';
import { LapKeuanganComponent } from './publikasi/lap-keuangan/lap-keuangan.component';
import { DetailBeritaComponent } from './publikasi/detail-berita/detail-berita.component'; // do not need declare in imports array
import { NgxPaginationModule } from 'ngx-pagination';
import { ProfilpejabatComponent } from './profil/profilpejabat/profilpejabat.component';
import { AlamatComponent } from './profil/alamat/alamat.component';
import { LhkpnComponent } from './profil/lhkpn/lhkpn.component';
import { SatriyaComponent } from './profil/satriya/satriya.component';
import { SejarahdinasComponent } from './profil/sejarahdinas/sejarahdinas.component';
import { ApiptkComponent } from './apiptk/apiptk.component';
import { PeraturanComponent } from './publikasi/peraturan/peraturan.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfviewDialogComponent } from './publikasi/peraturan/pdfview-dialog/pdfview-dialog.component';


@NgModule({
  declarations: [
    ClientpagesComponent,
    BerandaComponent,
    PpidComponent,
    PublikasiComponent,
    CpHeaderComponent,
    CpFooterComponent,
    CpBarheaderComponent,
    VisimisiComponent,
    TupoksiComponent,
    SotkComponent,
    InfoBerkalaComponent,
    InfoSertamertaComponent,
    InfoTiapsaatComponent,
    RenstraComponent,
    BeritaComponent,
    LapKinerjaComponent,
    LapKeuanganComponent,
    DetailBeritaComponent,
    ProfilpejabatComponent,
    AlamatComponent,
    LhkpnComponent,
    SatriyaComponent,
    SejarahdinasComponent,
    ApiptkComponent,
    PeraturanComponent,
    PdfviewDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    HttpClientModule,
    SharedModule.forRoot(),
    NgxPaginationModule,
    FormsModule,
    QuillModule,
    NgxExtendedPdfViewerModule
  ],
})
export class ClientpagesModule { }

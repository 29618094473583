// import { MatPaginatorIntl } from '@angular/material/paginator';

// export function CustomPaginator() {
//   const customPaginatorIntl = new MatPaginatorIntl();

//   customPaginatorIntl.itemsPerPageLabel = 'Data per Halaman:';
//   customPaginatorIntl.firstPageLabel = 'Pertama';
//   customPaginatorIntl.previousPageLabel = 'Sebelumnya';
//   customPaginatorIntl.nextPageLabel = 'Setelahnya';
//   customPaginatorIntl.lastPageLabel = 'Terakhir';
//   // customPaginatorIntl.getRangeLabel = dutchRangeLabel;

//   return customPaginatorIntl;
// }
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super();  

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {

      this.itemsPerPageLabel = "Data per Halaman";
      this.firstPageLabel = "Pertama";
      this.nextPageLabel = "Berikutnya";
      this.previousPageLabel = "Sebelumnya";
      this.lastPageLabel = "Terakhir";
      this.changes.next();

  }

 getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `Data ${startIndex + 1} - ${endIndex} dari ${length}`;
    // return `${startIndex + 1} - ${endIndex} / ${length}`;
  }
}
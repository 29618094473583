import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InputBeritaComponent } from './input-berita/input-berita.component';
import { KontributorRoutingModule } from './kontributor-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MaterialModule } from '../material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuillModule } from 'ngx-quill';
import { LibrariesModule } from '../libraries/libraries.module';


@NgModule({
  declarations: [
    DashboardComponent,
    InputBeritaComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    KontributorRoutingModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    NgbModule,
    QuillModule
  ],
})
export class KontributorModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientpages',
  templateUrl: './clientpages.component.html',
  styleUrls: ['./clientpages.component.css']
})
export class ClientpagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

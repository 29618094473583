<!-- <p>beranda works!</p> -->
<div class="row mt-3">
    <div class="col-12">
      <ngb-carousel *ngIf="listCarousel">
         <ng-template ngbSlide *ngFor="let slide of listCarousel" [id]="slide.id">
            <div class="picsum-img-wrapper text-center">
               <!-- <img class="img-fluid rounded" src="{{baseUrl}}/resources/carousel/{{slide.fotocar}}" [alt]="slide.id" style="max-height:500px; min-height:500px; margin:0 auto; width: fit-content;"> -->
               <img class="img-fluid" src="{{baseUrl}}/resources/carousel/{{slide.fotocar}}" [alt]="slide.id" style="max-height:500px; margin:0 auto; width: fit-content;">
            </div>
            <div class="carousel-caption">
               <h3 style="font-size: 24pt;">{{slide.judulcar}}</h3>
               <p style="font-size: medium;">{{slide.deskripsicar}}</p>
            </div>
         </ng-template>
      </ngb-carousel>
    </div>
 </div>

 <div class="row mt-5" style="background-color:#ffcb05; height: 30pt; margin-left: 0.5pt; margin-right: 1px;">
    <h3 class="mx-auto my-auto" style="color: #02275d; font-size: 16pt;">Warta Dispertaru DIY</h3>
 </div>

 <div class="mt-4 bg-light py-3 px-3" style="margin-left: 0.5pt; margin-right: 1px;">
    <div class="row">
      <div class="col-md-3 mb-3" *ngFor="let brt of listBerita; let i=index">
         <div *ngIf="i<4">
            <!-- <div class="card"> -->
            <div class="card" routerLink="/detail-berita/{{brt.id}}" style="cursor: pointer;">
               <img class="card-img-top" src="{{baseUrl}}/resources/berita-img/{{brt.dokumentasi}}" alt="Foto Kegiatan" style="height: 200px;">
               <div class="card-body">
                  <p class="card-title judul">{{brt.judul | uppercase}}</p>
                  <p class="card-text deskripsi">{{brt.isiberita | removetag |removenbsp}}</p>
               </div>
               <div class="card-footer">
                  <p class="float-right my-auto">{{brt.createdat}}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="row">
      <div class="col-12">
         <button routerLink='/berita' class="btn btn-info btn-xl float-right">SEMUA BERITA</button>
      </div>
   </div>
 </div>

 <div class="row mt-4 px-2" style="background-color:#ffcb05; height: 30pt; margin-left: 0.5pt; margin-right: 1px;">
   <h3 class="mx-auto my-auto api-title" style="line-height: 18px; text-align: center;">Data Penyesuaian Sertifikat Tanah Desa sampai dengan {{currentDate}}</h3>
</div>

 <div class="mt-2 bg-light py-3 px-3" style="margin-left: 0.5pt; margin-right: 1px;">
   <app-apiptk></app-apiptk>
</div>

<div class="row mt-5">
   <p class="mx-auto my-auto section-title">Tautan</p>
</div>
<div class="row mx-auto mt-4 tautan-container">
   <table>
      <tr>
         <td class="text-center" style="border-right: 1px solid #666;"><a class="textlogo" (click)="gotoElapor()">e-Lapor DIY</a></td>
         <td class="text-center text-jdih" style="border-right: 1px solid #666;"><a class="textlogo" (click)="gotoJdih()">J D I H</a></td>
         <td class="text-center" style="border-right: 1px solid #666;"><a class="textlogo" (click)="gotoSengguh()">Sengguh</a></td>
         <td class="text-center" style="border-right: 1px solid #666;"><a class="textlogo" (click)="gotoIntan()">Intantaru App</a></td>
         <td class="text-center" style="border-right: 1px solid #666;"><a class="textlogo" (click)="gotoAio()">All-in-One App</a></td>
         <td class="text-center"><a class="textlogo" (click)="gotoCC()">Command Center</a></td>
      </tr>
      <tr style="height: 20px;"></tr>
      <tr>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo1 mx-auto" (click)="gotoElapor()"></mat-icon></td>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo2 mx-auto" (click)="gotoJdih()"></mat-icon></td>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo3 mx-auto" (click)="gotoSengguh()"></mat-icon></td>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo4 mx-auto" (click)="gotoIntan()"></mat-icon></td>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo5 mx-auto" (click)="gotoAio()"></mat-icon></td>
         <td class="text-center" style="vertical-align: middle;"><mat-icon class="linklogo6 mx-auto" (click)="gotoCC()"></mat-icon></td>
      </tr>
   </table>
</div>

<!-- <div class="row justify-content-center mt-3">
   <div class="row" style="display: inline-block;">
      <a class="text-center textlogo" (click)="gotoElapor()">e-Lapor DIY</a>
      <span class="mx-3" style="color: #02275d;">|</span>
      <a class="text-center textlogo" (click)="gotoJdih()">J D I H</a>
      <span class="mx-3" style="color: #02275d;">|</span>
      <a class="text-center textlogo" (click)="gotoSengguh()">Sengguh</a>
      <span class="mx-3" style="color: #02275d;">|</span>
      <a class="text-center textlogo" (click)="gotoIntan()">Intantaru App</a>
      <span class="mx-3" style="color: #02275d;">|</span>
      <a class="text-center textlogo" (click)="gotoAio()">All-in-One App</a>
   </div>
</div> -->

 <!-- <div class="row mt-5">
    <div class="container rowlogo" style="display: flex;">
       <mat-icon class="linklogo1 mx-auto" style="height: 80px; width: 50px;" (click)="gotoElapor()"></mat-icon>
       <mat-icon class="linklogo2 mx-auto" style="height: 80px; width: 50px;" (click)="gotoJdih()"></mat-icon>
       <mat-icon class="linklogo3 mx-auto" style="height: 80px; width: 50px;" (click)="gotoSengguh()"></mat-icon>
       <mat-icon class="linklogo4 mx-auto" style="height: 80px; width: 50px;" (click)="gotoIntan()"></mat-icon>
       <mat-icon class="linklogo5 mx-auto" style="height: 100px; width: 62px;" (click)="gotoAio()"></mat-icon>
    </div>
 </div> -->

 <br>

 <div class="row">
    <div class="container"><hr></div>
 </div>

 <div class="row mt-2">
    <h1 class="mx-auto my-auto ta-title">Anggaran DPTR DIY dari Tahun ke Tahun</h1>
 </div>

 <div class="row mt-4 justify-content-center">
       <div class="box-anggaran mx-4 mt-2">
          <h2 class="anggaran-title my-auto" style="color: #02275d;">NON URUSAN</h2>
       </div>
       <div class="box-anggaran mx-4 mt-2">
          <h2 class="anggaran-title my-auto" style="color: #02275d;">URUSAN PERTANAHAN</h2>
       </div>
       <div class="box-anggaran mx-4 mt-2">
          <h2 class="anggaran-title my-auto" style="color: #02275d;">URUSAN <br> TATA RUANG</h2>
       </div>
 </div>

 <br><br><br>

<div class="container-fluid">
   <nav class="row navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand navbrand-pc" style="margin-left: 0;" href="#">
         <img src="./assets/diyfavicon.png" height="30" class="d-inline-block align-center" style="margin-top: -5px;" alt="">
         DISPERTARU DIY
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
         <ul class="navbar-nav ml-auto">
            <li class="nav-item">
               <a class="nav-link" routerLink="/">BERANDA</a>
            </li>
            <li class="nav-item dropdown">
               <a class="nav-link dropdown-toggle" href="#" id="profilDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  PROFIL
               </a>
               <div class="dropdown-menu" aria-labelledby="profilDropdown">
                  <a class="dropdown-item" routerLink="/visimisi">Visi dan Misi</a>
                  <a class="dropdown-item" routerLink="/tupoksi">Tugas dan Fungsi</a>
                  <a class="dropdown-item" routerLink="/sotk">Struktur Organisasi</a>
                  <a class="dropdown-item" routerLink="/profilpejabat">Profil Pejabat</a>
                  <a class="dropdown-item" routerLink="/lhkpn">LHKPN Pejabat</a>
                  <a class="dropdown-item" routerLink="/alamat">Alamat dan Sosial Media</a>
                  <a class="dropdown-item" routerLink="/sejarahdinas">Sejarah Dinas</a>
                  <a class="dropdown-item" routerLink="/satriya">Budaya Satriya</a>
               </div>
            </li>
            <li class="nav-item dropdown">
               <a class="nav-link dropdown-toggle" href="#" id="ppidDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  P P I D
               </a>
               <div class="dropdown-menu" aria-labelledby="ppidDropdown">
                  <a class="dropdown-item" routerLink="/info-berkala">Informasi Berkala</a>
                  <a class="dropdown-item" routerLink="/info-sertamerta">Informasi Serta Merta</a>
                  <a class="dropdown-item" routerLink="/info-tiapsaat">Informasi Setiap Saat</a>
               </div>
            </li>
            <li class="nav-item dropdown">
               <a class="nav-link dropdown-toggle" href="#" id="publikasiDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  PUBLIKASI
               </a>
               <div class="dropdown-menu" aria-labelledby="publikasiDropdown">
                  <a class="dropdown-item" routerLink="/renstra">Rencana Strategis</a>
                  <a class="dropdown-item" routerLink="/berita">Berita</a>
                  <a class="dropdown-item" routerLink="/lap-kinerja">Laporan Kinerja</a>
                  <a class="dropdown-item" routerLink="/lap-keuangan">Laporan Keuangan</a>
                  <a class="dropdown-item" routerLink="/peraturan">Peraturan</a>
               </div>
            </li>
            <li class="nav-item dropdown">
               <div *ngIf="!this.authService.isLoggedIn(); else someOneLogin">
                  <a class="nav-link dropdown-toggle" href="#" id="adminDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     ADMIN
                  </a>
                  <div class="dropdown-menu" aria-labelledby="adminDropdown">
                     <a class="dropdown-item" routerLink="auth/login">Login</a>
                  </div>
               </div>
               <ng-template #someOneLogin>
                  <a class="nav-link dropdown-toggle user" style="color: #01347c; font-style: italic; font-weight: bold;" href="#" id="adminDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     {{this.authService.getUserName() | uppercase}}
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="adminDropdown" >
                     <a class="dropdown-item" (click) = "toDashboard()" >Halaman Admin</a>
                     <a class="dropdown-item" routerLink="/" (click)="this.authService.logOut()">Logout</a>
                  </div>
               </ng-template>
            </li>
         </ul>
      </div>
   </nav>
</div>

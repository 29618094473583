<!-- <p>login works!</p> -->

<div class="container h-100">
    <!-- <h5 *ngIf="message">{{message}}</h5> -->
    <div class="d-flex justify-content-center h-100">
        <div class="user_card" style="background-color: rgb(189, 189, 189);">
            <div class="d-flex justify-content-center">
                <div class="brand_logo_container">
                    <img src="./assets/diyfavicon.png" class="brand_logo" alt="Logo">
                    <!-- <img src="../../../assets/diyfavicon.png" class="brand_logo" alt="Logo"> -->
                </div>
            </div>
            <div class="d-flex justify-content-center form_container">
                <!-- <form [formGroup]="loginForm" (ngSubmit)="postdata(loginForm)" autocomplete="off"> -->
                <form [formGroup]="loginForm" (ngSubmit)="postdata(loginForm)" autocomplete="off">
                    <div class="input-group mb-4">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="material-icons" style="font-size: 14pt; color: white;">person</span>
                            </div>
                        </div>
                        <input type="text" name="username" formControlName="username" autocomplete="off" class="form-control input_user" placeholder="username" autofocus>
                        <!-- <div class="error" *ngIf="loginForm.controls['username'].hasError('required') 
                        && loginForm.controls['username'].touched">username wajib diisi dan telah terdaftar!</div> -->
                    </div>
                    <div class="input-group mb-5">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="material-icons" style="font-size: 14pt; color: white">lock</span>
                            </div>
                        </div>
                        <input type="password" name="password" formControlName="password" class="form-control input_pass" placeholder="password" autocomplete="off">
                        <!-- <div class="error" *ngIf="loginForm.controls['password'].hasError('required') 
                        && loginForm.controls['password'].touched">user ID wajib diisi dan telah terdaftar!</div> -->
                    </div>
                    <!-- <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="customControlInline">
                            <label class="custom-control-label" for="customControlInline">Remember me</label>
                        </div>
                    </div> -->
                    <div class="d-flex justify-content-center mt-3 login_container">
                        <button type="submit" class="btn login_btn" [disabled]="!loginForm.valid">Login</button>
                    </div>
                    <!-- <div class="d-flex justify-content-center mt-3 login_container">
                        <button type="button" [disabled]="loginForm.invalid" name="button" class="btn login_btn">Login</button>
                        <div class="error" *ngIf="invalidLogin">User ID atau password tidak valid!</div>
                    </div> -->
                </form>
            </div>
    
            <div class="mt-4">
                <div class="d-flex justify-content-center links">
                    <!-- Don't have an account? <a href="#" class="ml-2">Sign Up</a> -->
                </div>
                <div class="d-flex justify-content-center links">
                    <a href="#">Lupa password?</a>
                </div>
            </div>
        </div>
    </div>
</div>
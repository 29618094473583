import { Injectable } from '@angular/core';
import { CarouselModel } from 'src/app/model/carousel-model';
import { environment } from 'src/environments/environment';
import { CarouselInterface } from 'src/app/model/carousel-interface'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Decode } from 'src/app/shared/decode-token';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {

  constructor(
    private http:HttpClient,
    private jwt:Decode
  ) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.jwt.token().nama+':'+this.jwt.token().userrole),
      'USR-KEY':this.jwt.token().key,
      'enctype':'multipart/form-data'
    })
  };

  getCarousel():Observable<CarouselModel[]> {
    const serverURL = environment.baseApi+'carousel/data';
    return this.http.get<CarouselModel[]>(serverURL,this.jwt.httpHeder());
  }

  getSelectedCarousel(id):Observable<CarouselInterface> {
    const serverURL = environment.baseApi+'carousel/selected/'+id;
    return this.http.get<CarouselInterface>(serverURL,this.jwt.httpHeder());
  }

  updateCarousel(data){
    const serverURL = environment.baseApi+'/carousel/update';
    return this.http.post(serverURL,data,this.httpOptions);
  }
}

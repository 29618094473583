<!-- <p>clientpages works!</p> -->
  <div class="container-fluid">
    <app-cp-barheader></app-cp-barheader>
  </div>
  
  <app-cp-header></app-cp-header>
  
  <div class="container-fluid" style="min-height: 80vh;">
    <router-outlet></router-outlet>
  </div>
  
  <div class="footer myfooter">
    <app-cp-footer></app-cp-footer>
  </div>
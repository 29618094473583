<!-- <p>alamat works!</p> -->
<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header h5">
                    Alamat dan Sosial Media
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <img src="./assets/image/logo pemprov diy2.png" style="height: 125px;" alt="lodo-diy">
                        <div style="width: 25px;"></div>
                        <div>
                            <h2 class="mb-1">Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta</h2>
                            <img src="./assets/image/dptr-jawa.png" style="height: 45px;" alt="lodo-diy">
                            <h2 style="font-style: italic; font-weight: lighter;">(Kundha Niti Mandala Sarta Tata Sasana)</h2>
                        </div>
                    </div>
                    <br><br>
                    <div class="d-flex flex-row align-items-center">
                        <div style="flex: 1.5;">
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="alamat"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">Jalan Tentara Rakyat Mataram No.4 Yogyakarta, 55231</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="telepon"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">(0274) 588219 + fax ; (0274) 2924586</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="website"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">www.dispertaru.jogjaprov.go.id</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="email"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">dispertaru@jogjaprov.go.id</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="twitter"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">@dispertarudiy</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="instagram"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">@dispertarudiy</h2>
                            </div>
                            <div class="address-container">
                                <mat-icon style="transform: scale(1.3);" svgIcon="youtube"></mat-icon>
                                <h2 class="ml-3 mb-0" style="font-weight: 200;">www.youtube.com/channel/UCFtGDK1-g4ZUb_tbhBNrkpw</h2>
                            </div>
                        </div>
    
                        <div class="gmap_canvas" style="border: 5px solid #999; flex: 1;">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63248.29252902558!2d110.3580252!3d-7.7878858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a58231b97dd27%3A0xebed93772611b9c0!2sDinas%20Pertanahan%20Dan%20Tata%20Ruang%20Daerah%20Istimewa%20Yogyakarta!5e0!3m2!1sen!2sid!4v1656219527817!5m2!1sen!2sid" width="500" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            <style>.mapouter{position:relative;text-align:right;height:330px;width:600px;}</style>
                            <style>.gmap_canvas {overflow:hidden;background:none!important;height:330px;width:600px;}</style>
                        </div>
                        <!-- <div class="gmap_canvas" style="border: 5px solid #999; flex: 1;">
                            <iframe width="600" height="330" id="gmap_canvas" src="https://maps.google.com/maps?q=Dinas%20Pertanahan%20dan%20Tata%20Ruang%20DIY&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            <a href="https://putlocker-is.org">putlocker</a>
                            <br>
                            <style>.mapouter{position:relative;text-align:right;height:330px;width:600px;}</style>
                            <a href="https://www.embedgooglemap.net">google embed</a>
                            <style>.gmap_canvas {overflow:hidden;background:none!important;height:330px;width:600px;}</style>
                        </div> -->
                    </div>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>SETTING - DAFTAR USER</span>
    <button mat-raised-button color="primary" style="float: right;" (click)="openUserCrudDialog()">Tambah <mat-icon aria-hidden="false" aria-label="new icon">add</mat-icon></button>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-sm-12">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyUserFilter($event.target.value)" placeholder="Ex. name" #input>
            </mat-form-field>
            <div class="mat-elevation-z8">
                <mat-table [dataSource]="dataSourceUser" style="width: 100%;" matSort #TableUserSort="matSort">
                    <ng-container matColumnDef="nama">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Nama </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.nama}} </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="bidang">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Bidang </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.bidang}} </mat-cell>
                    </ng-container>
                    
                    <ng-container matColumnDef="subbidang">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Subbidang </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.subbidang}} </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="akses">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Akses </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.userrole}} </mat-cell>
                    </ng-container>
        
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> <mat-icon>settings</mat-icon></mat-header-cell>
                        <mat-cell *matCellDef="let row" fxLayoutGap="5px">
                            <button mat-icon-button color="primary" (click)="onEdit(row)"><mat-icon>edit</mat-icon></button>
                            <button *ngIf = "row.is_lock == 0" mat-icon-button color="accent" (click)="onDelete(row)"><mat-icon>delete</mat-icon></button>
                            <button *ngIf = "row.is_lock == 1" mat-icon-button color="accent" ><mat-icon>lock</mat-icon></button>
                        </mat-cell>
                    </ng-container>
                    
                    <mat-header-row *matHeaderRowDef="displayColUser"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayColUser;"></mat-row>
                </mat-table>
                <mat-paginator #TableUserPaginator="matPaginator" [pageSize]="5" [pageSizeOptions]="[5, 10, 25]" [showFirstLastButtons]="true"></mat-paginator>
            </div>
        </div>
    </div>
</div>
 

 
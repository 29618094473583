import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Loading } from 'src/app/shared/loading';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-profilpejabat',
  templateUrl: './profilpejabat.component.html',
  styleUrls: ['./profilpejabat.component.css']
})
export class ProfilpejabatComponent implements OnInit {

   tabTitle = 'DPTR-DIY | Pejabat';
   daftarPejabat: any;

   constructor(
      private titleService: Title,
      private service: ClientService,
      private loading: Loading,
   ) { }

   ngOnInit(): void {
      this.titleService.setTitle(this.tabTitle);
      this.loading.show();
      this.service.getPejabat().subscribe(response => {
            // console.log(response);
            this.loading.hide();
            this.daftarPejabat = response;
         }
      );
   }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-peraturan',
  templateUrl: './peraturan.component.html',
  styleUrls: ['./peraturan.component.css']
})
export class PeraturanComponent implements OnInit {
  baseFileUrl = environment.baseApi + 'resources/file/laporan/peraturan/'
  filename = '';
  pdfData: any;
  srcURL: SafeResourceUrl;
  data
  tabTitle = "DPTR-DIY | Pub-LapKeu";
  onProcess: boolean = false;

  constructor(
    private titleService: Title,
    private _http: HttpClient,
    public service:ClientService,
    private loading:Loading,
    private alert:Alert,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    let res
    this.loading.show()
    this.titleService.setTitle(this.tabTitle)
    this.service.laporan('peraturan').subscribe(
      response=>{
        // console.log(response);
        this.loading.hide()
        res = response
        this.data =res.data
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menmpilkan Data')
        console.log(err)
      }
    )

  }

  public loadLargeFile(filename): void {
    this._http
      .get(
        'https://dispertaru.jogjaprov.go.id/api/resources/file/laporan/peraturan/' + filename,
        { responseType: 'blob' }
      )
      .subscribe((res) => this.pdfData = res);
  }

  loadPdfFile(filename) {
    this.filename = filename;
    this.service.showPdfFile = true
  }

  previewPDF(e) {
    // console.log(e);
    this.filename = e;
    this.service.showPdfFile = true

    this.service.getPdfFile(e).subscribe(data => {
      // console.log(data);
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      // window.open(url, '_blank');
      this.pdfData = url
    });

  }

  displayPDF(filename: any) {
    this.service.showPdfFile = true
    this.filename = filename;
    this.service.generatePdfFile(filename).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let url = window.URL.createObjectURL(blob);
      this.pdfData = url;
      //window.open(url);
    });
  }

  downloadPDF(e) {
    console.log(e);
    this.filename = e;

    this.service.getPdfFile(e).subscribe(data => {
      // console.log(data);
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');

      // this.onProcess = true;
      // setTimeout(() => {
      //   const blob = new Blob([data], { type: 'application/pdf' });
      //   const url = window.URL.createObjectURL(blob);
      //   window.open(url, '_blank');
      //   this.onProcess = false;
      // }, 5000);
    });
  }

  testViewPDF(filename) {
    this.service.showPdfFile = true
    this.filename = filename;
    this.service.getBinaryData(filename)
      .subscribe((pdf: ArrayBuffer) => {
        const binaryData = [];
        const bytes = new Uint8Array(pdf);
        for (let i = 0; i < bytes.length; i++) {
          binaryData.push(String.fromCharCode(bytes[i]));
        }
        const binaryString = binaryData.join('');
        const pdfUrl = 'data:application/pdf;base64,' + btoa(binaryString);
        this.pdfData = pdfUrl;
      });
  }

}

/** Example file/folder data. */
export const menu = [
  {
    name:'DASHBOARD',
    icon:'dashboard',
    routeLink:"dashboard",
    role:'Admin',
  },
  {
    name: 'Profil',
    icon: 'account_circle',
    routeLink:"#",
    role:'Admin',
    children: [
      {
        name: 'Visi Misi',
        icon: 'note_alt',
        routeLink:"admvisimisi",
        role:'Admin',
      },
      {
        name: 'Tupoksi',
        icon: 'history_edu',
        routeLink:"admtupoksi",
        role:'Admin',
      },
      {
        name: 'SOTK',
        icon: 'grading',
        routeLink:"admsotk",
        role:'Admin',
      },
    ]
  },
  {
    name: 'PPID',
    icon: 'home_repair_service',
    routeLink:"#",
    role:'Admin',
    children: [
      {
        name: 'Berkala',
        icon: 'edit_calendar',
        routeLink:"berkala",
        role:'Admin',
      },
      {
        name: 'Serta Merta',
        icon: 'event_note',
        routeLink:"sertamerta",
        role:'Admin',
      },
      {
        name: 'Tiap Saat',
        icon: 'schedule',
        routeLink:"tiapsaat",
        role:'Admin',
      },
    ]
  },
  {
    name: 'PUBLIKASI',
    icon: 'public',
    routeLink:"#",
    role:'Admin',
    children: [
      {
        name: 'Renstra',
        icon: 'article',
        routeLink:"restra",
        role:'Admin',
      },
      {
        name: 'Berita',
        icon: 'article',
        routeLink:"berita",
        role:'Admin',
      },
      {
        name: 'Lap. Kinerja',
        icon: 'article',
        routeLink:"laporan_kinerja",
        role:'Admin',
      },
      {
        name: 'Lap. Keuangan',
        icon: 'article',
        routeLink:"laporan_keuangan",
        role:'Admin',
      },
      {
        name: 'Peraturan',
        icon: 'article',
        routeLink:"peraturan",
        role:'Admin',
      },
    ]
  },
  {
    name: 'Dashboard penulis',
    icon: 'article',
    routeLink:"dashboard",
    role:'Kontributor',
  },
  {
    name: 'Publikasi',
    icon: 'article',
    routeLink:"#",
    role:'Kontributor',
    children:[
      {
        name: 'Tulis Berita',
        icon: 'article',
        routeLink:"input-berita",
        role:'Kontributor',
      }
    ]
  },
  {
    name: 'Setting',
    icon: 'settings',
    routeLink:"#",
    role:'Admin',
    children:[
      {
        name: 'User',
        icon: 'person',
        routeLink:"users",
        role:'Admin',
      },
      {
        name: 'Carousel',
        icon: 'slideshow',
        routeLink:"admcarousel",
        role:'Admin',
      }
    ]
  }
];

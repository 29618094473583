<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>DASHBOARD</span>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
                <span class="info-box-icon bg-info elevation-1"><mat-icon>article</mat-icon></span>
                <div class="info-box-content">
                    <span class="info-box-text">Jumlah Berita</span>
                    <span class="info-box-number">
                        {{totalBerita.total}} <small>Berita</small>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
                <span class="info-box-icon bg-success elevation-1"><mat-icon>public</mat-icon></span>
                <div class="info-box-content">
                    <span class="info-box-text">Dipublikasikan</span>
                    <span class="info-box-number">
                        {{totalBerita.active}} <small>Berita</small>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
                <span class="info-box-icon bg-warning elevation-1"><mat-icon>pending</mat-icon></span>
                <div class="info-box-content">
                    <span class="info-box-text">Pending</span>
                    <span class="info-box-number">
                        {{totalBerita.pending}} <small>Berita</small>
                    </span>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6 col-md-3">
            <div class="info-box">
                <span class="info-box-icon bg-danger elevation-1"><mat-icon>archive</mat-icon></span>
                <div class="info-box-content">
                    <span class="info-box-text">Diarsipkan</span>
                    <span class="info-box-number">
                        {{totalBerita.off}} <small>Berita</small>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <p>cp-footer works!</p> -->
<div class="container-fluid">
    <div class="row bar-copyright" style="background-color:#02275d; color: white;">
        <p class="mb-2 mt-2 mx-auto" style="text-align: center;" [innerHTML]="txtCopyright"></p>
    </div>

    <div class="row justify-content-center pb-3" style="background-color:#01347c; color: white;">
        <div class="col-md-2 logo-footer">
            <img src="./assets/image/logo pemprov diy2.png" class="float-right mt-2" style="height: 90px" alt="logo Pemda DIY">
        </div>
        <div class="col-md-5">
            <p class="mt-4 footer-address">
                Hak Cipta Dispertaru DIY<br />
                Jalan Tentara Rakyat Mataram Nomor 4<br />
                Telp./Fax: (0274) 588219<br />
                Yogyakarta 55231
            </p>
        </div>
        <div class="col-md-4">
            <div class="row mt-4 justify-content-center">
                <div class="box-sosmed">
                    <mat-icon class="" style="height: 20px; margin-top: -3px" svgIcon="twit" (click)="gotoPtrFb()"></mat-icon>
                </div>
                <div class="box-sosmed ml-2">
                    <mat-icon class="" style="height: 20px; margin-top: -3px" svgIcon="insta" (click)="gotoPtrInsta()"></mat-icon>
                </div>
                <div class="box-sosmed ml-2">
                    <mat-icon class="" style="height: 20px; margin-top: -3px" svgIcon="youtube" (click)="gotoYoutube()"></mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
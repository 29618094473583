import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';
@Component({
  selector: 'app-info-tiapsaat',
  templateUrl: './info-tiapsaat.component.html',
  styleUrls: ['./info-tiapsaat.component.css']
})
export class InfoTiapsaatComponent implements OnInit {
  
  // url:String = baseUrl + './../../../../assets/ppidfiles/'
  baseFileUrl = environment.baseApi + 'resources/file/ppid/tiapsaat/'
  data 

  tabTitle = "DPTR-DIY | PPID-tiapsaat";

  constructor(
    private titleService: Title,
    private loading:Loading,
    private alert:Alert,
    private service:ClientService
  ) { }

  ngOnInit(): void {
    let res
    this.loading.show()
    this.titleService.setTitle(this.tabTitle)
    this.service.tiapSaat().subscribe(
      response=>{
        this.loading.hide()
        res = response
        this.data = res.data
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Data')
        console.log(err)
      }
    )
  }

}

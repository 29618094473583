<!-- <p>sejarahdinas works!</p> -->
<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header h5">
                    Sejarah Dinas Pertanahan dan Tata Ruang DIY
                </div>
                <div class="card-body">
                    <img class="logoparagraph" src="./assets/image/foto-kantordinas.jpeg" style="height: 250px; border: 2px solid #666;" alt="lodo-satriya-diy">
                    <div>
                        <p class="paragraph">
                            <!-- Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta (Dispertaru DIY) berdiri sejak tahun 2016. Dasar hukum pembentukan Dispertaru DIY tertuang dalam Bab II Pasal 4 Ayat (1) huruf f angka 2 pada Peraturan Daerah Daerah Istimewa Yogyakarta Nomor 3 Tahun 2015 tanggal 17 Juni 2015 tentang Kelembagaan Pemerintah Daerah Daerah Istimewa Yogyakarta. 
                            <br>
                            Dalam Perda Keistimewaan DIY No 3 Tahun 2015 tentang Kelembagaan Pemerintah Daerah DIY tersebut dijelaskan bahwa Dispertaru DIY dibentuk dalam rangka mewadahi urusan Pertanahan dan Tata Ruang yang melaksanakan urusan wajib dan urusan keistimewaan. Hingga tahun 2015, urusan pertanahan dan tata ruang tidak diampu oleh satu dinas/lembaga. Urusan pertanahan sebelumnya diampu oleh Biro Tata Pemerintahan Setda DIY, sedangkan urusan tata ruang diampu oleh Dinas Pekerjaan Umum, Perumahan, dan Energi Sumberdaya Mineral DIY bidang tata ruang. -->
                            {{paragraf1}}
                        </p>
                        <div class="d-flex">
                            <div class="paragraph2col" style="width: 47%;">
                                <p class="mb-0"  style="text-indent: 50px;">
                                    <!-- Pengaturan dan pelaksanaan urusan tata ruang tidak dapat dipisahkan antara tata ruang secara umum dan tata ruang tanah Kasultanan dan Kadipaten. Oleh karena itu, untuk tujuan efisiensi dan efektivitas penyelenggaraan urusan tata ruang istimewa di DIY, maka penyelenggaraan urusan tata ruang diwadahi dalam lembaga yang sama. Hal ini diperkuat dengan Peraturan Gubernur DIY No 55 tahun 2015 tentang Rincian Tugas dan Fungsi Dinas Pertanahan dan Tata Ruang, pada pasal 3 ayat (1) yang menyebutkan bahwa Dinas Pertanahan dan Tata Ruang DIY mempunyai tugas melaksanakan urusan Pemerintah Daerah di bidang pertanahan, tata ruang, dekonsentrasi serta tugas pembantuan yang diberikan oleh Pemerintah. -->
                                    {{paragraf2}}
                                </p>
                                <br>
                                <p style="text-indent: 50px;">
                                    <!-- Dispertaru DIY terletak di Jalan Tentara Rakyat Mataram Nomor 4 Yogyakarta yang merupakan bekas gedung Perpustakaan Daerah. Pada awal berdirinya, Dispertaru DIY dipimpin oleh seorang Pelaksana Tugas Kepala Dinas yaitu Ir. Rani Syamsinarsi, M.T. dimana pada saat itu beliau juga merupakan Kepala Dinas Pekerjaan Umum, Perumahan dan Energi Sumber Daya Mineral Daerah Istimewa Yogyakarta. -->
                                    {{paragraf3}}
                                </p>
                            </div>
                            <div style="width: 50px;"></div>
                            <div class="paragraph2col" style="width: 53%;">
                                <p class="mb-0" style="text-indent: 50px;">
                                    <!-- Pada bulan Juli 2016, Ir. R. Hananto Hadi Purnomo, M.Sc. dilantik sebagai Kepala Dispertaru DIY selama kurang lebih 1 tahun 4 bulan. Pada Oktober 2017, dilantik pejabat baru yaitu Drs. Krido Suprayitno, S.E., M.Si. yang secara resmi menjabat sebagai Kepala Dispertaru DIY sampai saat ini. -->
                                    {{paragraf4}}
                                </p>
                                <br>
                                <p style="text-indent: 50px;">
                                    <!-- Seiring dengan dinamika perkembangan dan adanya penataan kelembagaan baru Pemerintah Daerah Daerah Istimewa Yogyakarta, sesuai dengan Perda Istimewa DIY Nomor 1 Tahun 2018, Susunan Organisasi, Tugas, Fungsi dan Tata Kerja Dispertaru DIY mengalami penyesuaian. Rincian Tugas dan fungsi Dispertaru DIY tertuang dalam Peraturan Gubernur Daerah Istimewa Yogyakarta Nomor 19 tahun 2020 tentang Kedudukan, Susunan Organisasi, Tugas, Fungsi, dan Tata Kerja Dinas Pertanahan dan Tata Ruang (Kundha Niti Mandala Sarta Tata Sasana) Daerah Istimewa Yogyakarta. -->
                                    {{paragraf5}}
                                </p>
                                <ol style="padding-left: 17px;">
                                    <!-- <li>Ir. Rani Sjamsinarsi, M.T. (2016);</li>
                                    <li>Ir. R. Hananto Hadi Purnomo, M.Sc. (2016-2017);</li>
                                    <li>Drs. Krido Suprayitno, S.E., M.Si. (2017-sekarang);</li> -->
                                    <li *ngFor="let kadis of listKadis">{{kadis.nama_kadis}}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>


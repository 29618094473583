import { TupoksiModel } from './../../../model/tupoksi-model';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ClientService } from '../../client.service';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';

@Component({
  selector: 'app-tupoksi',
  templateUrl: './tupoksi.component.html',
  styleUrls: ['./tupoksi.component.css']
})
export class TupoksiComponent implements OnInit {

  tabTitle = "DPTR-DIY | Tupoksi";
  dataTupoksi

  constructor(
    private titleService: Title, 
    private service:ClientService,
    private loading:Loading,
    private alert:Alert
    ) { }

  ngOnInit(): void {
    let res
    this.loading.show()
    this.titleService.setTitle(this.tabTitle);

    this.service.getTupoksi().subscribe(
      data=>{
        this.loading.hide()
        res = data
        this.dataTupoksi = res.data.uraian_tupoksi;
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Data')
        console.log(err)
      }
    )
  }

}

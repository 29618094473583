import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removenbsp'
})
export class RemovenbspPipe implements PipeTransform {

  transform(text: string, ...usefulTags: any[]): string {
    return text.replace(/&nbsp;/g, ' ');
  }

}

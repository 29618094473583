import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})


export class ConfirmDialogComponent implements OnInit {

  type: string
  title: string;
  message: string;
  icon:string 

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    switch (data.type) {
      case 'warning':
        this.icon = 'error_outline'
        break;
      case 'danger':
        this.icon = 'dangerous'
        break;
      default:
          this.icon = 'check_circle_outline'
        break;
    }
   }

  ngOnInit(): void {
    
    
  }

}

<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>Daftar Berita</span>
    <button mat-raised-button color="primary" style="float: right;" (click)="openDialog(formBerita)">Tambah <mat-icon aria-hidden="false" aria-label="new icon">add</mat-icon></button>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-sm-12">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>
                
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort class="table-responsive">
                
                    <!-- ID Column -->
                    <ng-container matColumnDef="no">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> NO </th>
                        <td mat-cell *matCellDef="let row;let i = index"> {{i+1}} </td>
                    </ng-container>
                    
                        <!-- Progress Column -->
                    <ng-container matColumnDef="penulis">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> PENULIS </th>
                        <td mat-cell *matCellDef="let row"> {{row.penulis}} </td>
                    </ng-container>
                    
                        <!-- Name Column -->
                    <ng-container matColumnDef="judul">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> JUDUL </th>
                        <td mat-cell *matCellDef="let row"> {{row.judul}} </td>
                    </ng-container>
                        <!-- Name Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                        <td mat-cell style="text-transform: uppercase;" *matCellDef="let row"> {{row.status=='off'? 'ditolak':row.status}} </td>                     
                    </ng-container>
                        <!-- Name Column -->
                    <ng-container matColumnDef="tgl_upload">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> TGL UPLOAD </th>
                        <td mat-cell *matCellDef="let row"> {{row.createdat}} </td>
                    </ng-container>
                    
                        <!-- Fruit Column -->
                    <ng-container matColumnDef="aksi">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header > <mat-icon>settings</mat-icon> </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="primary" aria-label="Edit" (click) = "onEdit(row);openDialog(formBerita)"><mat-icon>edit</mat-icon></button>
                            <button mat-icon-button color="warn" *ngIf="row.status !='active' &&  row.status!='off'" aria-label="Hapus" (click) = "onDelete(row.id)"><mat-icon>delete</mat-icon></button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="6">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
            </div>                      
        </div>    
    </div>
</div>


<ng-template #formBerita>
    <div class="col-md-4" style="padding-left: 0px;">
        <mat-toolbar style="height: 40px;" color="primary">
            <h2 mat-dialog-title >DRAFT BERITA</h2>
        </mat-toolbar>
    </div>
    <mat-dialog-content style="max-height: 80vh;">
        <div class="row">
            <div class="col-sm-4">
                <mat-form-field appearance="" style="width: 100%;" class="mt-1">
                    <mat-label>Judul</mat-label>
                    <textarea matInput name="judul" style="height: 20px;" [(ngModel)]="form.judul.value" spellcheck="false" required></textarea>
                </mat-form-field>
                <div class="input-group">
                    <div class="custom-file">
                        <input type="file" name="dokumentasi" accept="image/*" class="custom-file-input" (change)="onSelectFile($event)" required ngModel>
                        <label class="custom-file-label" for="dokumentasi" [innerHTML]="form.image.label"></label>
                    </div>
                    <div class="mt-3" style="width: 100%;">
                        <img [src]="form.image.url" style="width: 100%; max-width: 100%;">
                    </div>
                </div>
            </div>
            <div class="col-sm-8 mt-1">
                <mat-label>Isi Berita</mat-label>
                <quill-editor name="isiberita" id="isiberita" [styles]="{height:'500px'}" spellcheck="false" [(ngModel)]="form.isi.value" [modules]="editorModules" required ></quill-editor>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" layout="row">
        <div class="mr-2">
            <mat-slide-toggle name="beritaSlider" [(ngModel)]="form.isPublish" *ngIf="form.statusBerita!=='off'"  (change)="checkPublish()" color="primary" class="my-auto ml-5" >Publish</mat-slide-toggle>
        </div>
        <button mat-flat-button color="primary" *ngIf="form.statusBerita!=='off'" (click) = "onUpdate('simpan')"><mat-icon>save_alt</mat-icon> Simpan</button>
        <button mat-flat-button color="accent" *ngIf="form.statusBerita!=='off'" (click) = "onUpdate('archive')"><mat-icon>archive</mat-icon> Arsipkan</button>
        <button mat-flat-button color="warn" mat-dialog-close><mat-icon>close</mat-icon> Batal</button>
    </mat-dialog-actions>
</ng-template>
<div class="container">
   <div class="row">
      <h2 class="mt-3">PERATURAN</h2>
      <i class="fas fa-paperclip"></i> <i class="fas fa-paperclip"></i>
      <div class="col-sm-12">
         <div class="accordion" id="accordionExample" *ngFor="let dat of data">
            <div class="card mb-2">
                  <div class="card-header" [attr.id]="'heding'+dat.id" data-toggle="collapse" [attr.data-target]="'#colapse'+dat.id" aria-expanded="true" [attr.aria-controls]="'colapse'+dat.id" style="cursor: pointer;">
                     <h3 class="mb-0 mt-2 ml-2">
                        <b>{{dat.title}}</b>
                     </h3>
                  </div>

                  <div [attr.id]="'colapse'+dat.id" class="collapse" [attr.aria-labelledby]="'heding'+dat.id" data-parent="#accordionExample">
                     <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                              <div class="ql-editor" [innerHTML]="dat.deskripsi | safehtml"></div>
                              <div class="d-flex pr-2" *ngIf ="dat.file">
                              <!-- <div class="d-flex pr-2"> -->
                                 <button class="btn btn-sm btn-secondary px-0 mr-2" style="height: 26px;" (click)="loadPdfFile(dat.file)"><mat-icon style="font-size: medium;">visibility</mat-icon></button>
                                 <!-- <button *ngIf="onProcess" class="btn btn-sm btn-primary px-0" style="height: 26px; cursor: not-allowed"><i class="fas fa-spinner" style="font-size: medium;"></i></button>
                                 <button *ngIf="!onProcess" class="btn btn-sm btn-primary px-0" style="height: 26px;" (click)="downloadPDF(dat.file)"><mat-icon style="font-size: medium;">download</mat-icon></button> -->
                                 <!-- <a [attr.href]="baseFileUrl+dat.file" target="blank">
                                    <button class="btn btn-sm btn-primary px-0" style="height: 26px;"><mat-icon style="font-size: medium;">download</mat-icon></button>
                                 </a> -->
                              </div>
                        </div>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   </div>
   <app-pdfview-dialog *ngIf="this.service.showPdfFile" [fileToView]="pdfData" [filename]="filename"> </app-pdfview-dialog>

</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemovetagPipe } from './removetag.pipe';
import { RemovenbspPipe } from './removenbsp.pipe';
import { SafehtmlPipe } from './safehtml.pipe';



@NgModule({
  declarations: [
    RemovetagPipe,
    RemovenbspPipe,
    SafehtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    RemovetagPipe,
    RemovenbspPipe,
    SafehtmlPipe
  ]
})
export class SharedModule { 
  static forRoot() {
    return {
        ngModule: SharedModule,
        providers: [],
    };
 }
}

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '../client.service';
import * as Chart from 'chart.js';
import 'chartjs-plugin-labels';

@Component({
  selector: 'app-apiptk',
  templateUrl: './apiptk.component.html',
  styleUrls: ['./apiptk.component.css']
})
export class ApiptkComponent implements OnInit, AfterViewInit {

  constructor(private service: ClientService) { }

  @ViewChild('ptkKabBarChart') chartBarKabElementRef: ElementRef;
  chartBarKab: CanvasRenderingContext2D;
  @ViewChild('ptkKabPieChart') chartPieKabElementRef: ElementRef;
  chartPieKab: CanvasRenderingContext2D;

  @ViewChild('ptkKatBarChart') chartBarKatElementRef: ElementRef;
  chartBarKat: CanvasRenderingContext2D;
  @ViewChild('ptkKatPieChart') chartPieKatElementRef: ElementRef;
  chartPieKat: CanvasRenderingContext2D;

  // chartData: Chart.ChartData[] = [];
  // chartOptions: Chart.ChartOptions[] = [];
  // chart: Chart;

  dataPTKobs$: any;
  dataPTKstat: any;
  dataPTK: any;
  listKab = [];
  rekapKab = [];
  dataCatobs$: any;
  dataCatstat: any;
  dataCat: any;
  listCat = [];
  rekapCat = [];
  dataVer: any;
  dataVal: any;
  dataKjn: any;
  persenValVer: any;
  persenKjnVal: any;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.service.getPenyesuaianPTK().subscribe(resp => {
      // console.log(resp);
      this.dataPTKobs$ = resp.dataperkabupaten;
      this.dataPTKstat = resp.dataperkabupaten;
      this.dataPTK = this.dataPTKobs$;
      this.dataCatobs$ = resp.dataperkategori;
      this.dataCatstat = resp.dataperkategori;
      this.dataCat = this.dataCatobs$;
      // console.log(this.dataPTK);
      for (const item of this.dataPTK) {
        this.listKab.push(item.kabupaten);
        this.rekapKab.push(item.Total);
      }
      for (const item of this.dataCat) {
        this.listCat.push(item.kategori);
        this.rekapCat.push(item.berkas);
      }
      this.dataVer = this.rekapCat[0];
      this.dataVal = this.rekapCat[1];
      this.dataKjn = this.rekapCat[2];
      this.persenValVer = ((this.dataVal/this.dataVer)*100).toFixed(2);
      this.persenKjnVal = ((this.dataKjn/this.dataVal)*100).toFixed(2);
      // console.log(this.persenValVer);

      // console.log(this.rekapKab);
      // console.log(this.rekapCat);
      // console.log(Math.max(...this.rekapCat));

      this.chartBarKab = this.chartBarKabElementRef.nativeElement.getContext('2d');
      const myChartBarKab = new Chart(this.chartBarKab, {
        type: 'bar',
        data: {
          // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          labels: this.listKab,
          datasets: [{
              // data: [12, 19, 3, 5, 2],
              data: this.rekapKab,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)'
              ],
              borderWidth: 1,
          }],
        },
        options: {
          title: {
            display: true,
            text: 'per Kabupaten',
          },
          maintainAspectRatio: false,
          legend: { display: false },
          layout: {
            padding: {
              // top: 10,
              right: 15
            }
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'jumlah berkas'
              },
              ticks: {
                beginAtZero: true,
                stepSize: 2000 // Set the custom step size for the y-axis
              }
            }]
          },
          plugins: {
            labels: {
              render: 'value'
            },
          }
        }
      });

      this.chartPieKab = this.chartPieKabElementRef.nativeElement.getContext('2d');
      const myChartPieKab = new Chart(this.chartPieKab, {
        type: 'doughnut',
        data: {
          // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          labels: this.listKab,
          datasets: [{
              label: 'Jumlah Berkas',
              // data: [12, 19, 3, 5, 2],
              data: this.rekapKab,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)'
              ],
              borderWidth: 1
          }],
        },
        options: {
          legend: { display: false },
          plugins: {
            labels: {
              render: 'percentage',
              // precision: 1
            }
          }
        }
      });

      this.chartBarKat = this.chartBarKatElementRef.nativeElement.getContext('2d');
      const myChartBarKat = new Chart(this.chartBarKat, {
        type: 'bar',
        data: {
          // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          labels: this.listCat,
          datasets: [{
              // data: [12, 19, 3, 5, 2],
              data: this.rekapCat,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                // 'rgba(75, 192, 192, 0.2)',
                // 'rgba(153, 102, 255, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                // 'rgba(75, 192, 192, 1)',
                // 'rgba(153, 102, 255, 1)'
              ],
              borderWidth: 1
          }],
        },
        options: {
          title: {
            display: true,
            text: 'per Kategori',
          },
          maintainAspectRatio: false,
          legend: { display: false },
          layout: {
            padding: {
              // top: 10,
              right: 15
            }
          },
          scales: {
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'jumlah berkas'
              },
              ticks: {
                beginAtZero: true,
                stepSize: 2000 // Set the custom step size for the y-axis
              }
            }]
          },
          plugins: {
            labels: {
              render: 'value'
            }
          }
        }
      });

      this.chartPieKat = this.chartPieKatElementRef.nativeElement.getContext('2d');
      const myChartPieKat = new Chart(this.chartPieKat, {
        type: 'doughnut',
        data: {
          // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          labels: this.listCat,
          datasets: [{
              label: 'Jumlah Berkas',
              // data: [12, 19, 3, 5, 2],
              data: this.rekapCat,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)'
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)'
              ],
              borderWidth: 1
          }],
        },
        options: {
          legend: { display: false },
          plugins: {
            labels: {
              render: 'percentage',
              // precision: 1
            }
          }
        }
      });
    });
  }

}

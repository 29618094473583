import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Loading } from 'src/app/shared/loading';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-visimisi',
  templateUrl: './visimisi.component.html',
  styleUrls: ['./visimisi.component.css']
})
export class VisimisiComponent implements OnInit {

  tabTitle = "DPTR-DIY | Visi-Misi";
  dataVisimisi;

  constructor(private titleService: Title, private service:ClientService,private loading:Loading) { }

  ngOnInit(): void {
    let response
    this.loading.show()
    this.titleService.setTitle(this.tabTitle);
    this.service.getVisiMisi().subscribe(
      res=>{
        this.loading.hide()
        response = res
        this.dataVisimisi = response.data.uraian_visimisi;
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { VisimisiService } from './visimisi.service';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Loading } from 'src/app/shared/loading';
import { Alert } from 'src/app/shared/Alert';

@Component({
    selector: 'app-admvisimisi',
    templateUrl: './admvisimisi.component.html',
    styleUrls: ['./admvisimisi.component.css']
})
export class AdmvisimisiComponent implements OnInit {

    visimisi;
    id
    status
    showExistingVimi

    constructor(
        public dialog: MatDialog,
        private service:VisimisiService,
        private loading:Loading,
        private alert:Alert
        ) { }

    editorModules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background':[]}],
            [{'align': []}],
        ]
    }
    
    ngOnInit(): void {
        this.getvisimisi()
    }

    getvisimisi(){
        this.loading.show()
        this.service.getvisimisi().subscribe(
            res=>{
                this.status = res
                this.visimisi = this.status.data.uraian_visimisi;
                this.id = this.status.data.id
                this.showExistingVimi = true
                this.loading.hide()
            },
            err=>{
                this.alert.error('Gagal Menampilkan Data')
                console.log(err)
                this.loading.hide()
            }
        )
    }

    showVisiMisi() {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
              type: 'warning', //warning,danger,success
              title: 'Warning',
              message: 'Data dalam teks editor akan ditimpa dengan data Visi Misi eksistng.'
            }
        });
        confirmDialog.afterClosed().subscribe(result => {
            if (result === true) {
                this.loading.show()
              this.service.getvisimisi().subscribe(
                res=>{
                  this.status = res
                  this.visimisi = this.status.data.uraian_visimisi;
                  this.id = this.status.data.id
                  this.showExistingVimi = true
                  this.loading.hide()
                },
                err=>{
                  this.alert.error('Gagal Menampilkan Data')
                  console.log(err)
                  this.loading.hide()
                }
              )
            }
        });
    }

    hideVisiMisi(){
        this.visimisi = '';
        this.showExistingVimi = false
    }
    
    updateDataVisiMisi(){
        let data = {
            id:this.id,
            uraian : this.visimisi
        }
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
            data: {
              type: 'warning', //warning,danger,success
              title: 'Warning',
              message: 'Apakah Anda Yakin Akan Menyimpan Uraian Visi Misi?'
            }
        });
        confirmDialog.afterClosed().subscribe(result => {
            if (result === true) {
              this.service.updateVisiMisi(data).subscribe(
                res=>{
                    this.alert.success('Data Berhasil di Simpan')
                    this.status = res
                    location.reload()
                },
                err=>{
                  this.alert.error('Gagal Menyimpan Data')
                  console.log(err)
                }
              )
            }
        });
    }

}

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Decode } from 'src/app/shared/decode-token';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private jwt:Decode,private http:HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.jwt.token().nama+':'+this.jwt.token().userrole),
      'USR-KEY':this.jwt.token().key,
      'enctype':'multipart/form-data'
    })
  };

  getTotalBerita(){
    const serverURL = environment.baseApi+'dashboard/total_berita';
    return this.http.get(serverURL,this.jwt.httpHeder());
  }

  getBeritaPending(){
    let data = this.http.get(environment.baseApi+"dashboard/berita/pending",this.jwt.httpHeder())
    return data
  }

  deleteBerita(id){
    const serverURL = environment.baseApi+'berita/admin_delete';
    return this.http.post(serverURL,$.param({id:id}),this.jwt.httpHeder());
  }

  updateBerita(data){
    const serverURL = environment.baseApi+'berita/save_admin';
    return this.http.post(serverURL,data,this.httpOptions);
  }

}

import { AdminpagesModule } from './adminpages/adminpages.module';
import { AuthguardGuard } from './authguard.guard';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientpagesModule } from './clientpages/clientpages.module';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { Loading } from './shared/loading';
import { Decode } from './shared/decode-token';
import { Alert } from './shared/Alert';
import { KontributorModule } from './kontributor/kontributor.module';
import { AuthModule } from './auth/auth.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    ClientpagesModule,
    AdminpagesModule,
    HttpClientModule,
    SharedModule,
    MatDialogModule,
    KontributorModule,
    AuthModule
  ],
  providers: [AuthguardGuard,Loading,Decode,Alert],
  bootstrap: [AppComponent],
})
export class AppModule { }

<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>PROFIL - TUPOKSI</span>
</div>
<div class="card-body mat-elevation-z8">
    <div class="row">
        <div class="col-sm-12">
            <div class="row">
                <quill-editor name="tupoksi" id="tupoksi" [styles]="{height:'450px'}" spellcheck="false" [modules]="editorModules" [(ngModel)] = tupoksi></quill-editor>
            </div>
            <div class="row float-right mt-1">
                <button mat-flat-button color="primary" (click)="updateDataTupoksi()">UPDATE</button>
            </div>
            <div class="my-visibility">
                <button *ngIf="!showExistingTupoksi" mat-flat-button (click)="showTupoksi()"><mat-icon>visibility</mat-icon> Eksisting Tupoksi</button>
                <button *ngIf="showExistingTupoksi" mat-flat-button (click)="hideTupoksi()"><mat-icon>visibility_off</mat-icon> Eksisting Tupoksi</button>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-satriya',
  templateUrl: './satriya.component.html',
  styleUrls: ['./satriya.component.css']
})
export class SatriyaComponent implements OnInit {
   tabTitle = 'DPTR-DIY | Satriya';

   constructor(private titleService: Title) { }

   ngOnInit(): void {
      this.titleService.setTitle(this.tabTitle);
   }

}

<div class="example-container">
   <mat-toolbar class="example-toolbar" style="background-color: #02275d; color: #fff;">
      <img src="./assets/image/logo pemprov diy2.png" class="float-right" style="height: 45px;margin-right: 10px;margin-bottom: 5px;" alt="logo Pemda DIY">
      <span class="h6" style="margin-bottom: 0px;">DINAS PERTANAHAN <br> DAN TATA RUANG</span>
      <button mat-icon-button (click)="snav.toggle()" style="margin-left: 20px;"><mat-icon>menu</mat-icon></button>
      <a mat-stroked-button routerLink="/"><mat-icon>home</mat-icon>Beranda</a>
      <!-- <button mat-button routerLink="/" style="border: 1px #fff;"><mat-icon>home</mat-icon></button> -->
      <span style="flex:1 1 auto"></span>
      <button mat-button [matMenuTriggerFor]="beforeMenu">{{adminLogin}} <mat-icon>account_circle</mat-icon></button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
         <button mat-menu-item mr-1 routerLink="/" (click)="this.authService.logOut()">Keluar <mat-icon>exit_to_app</mat-icon></button>
      </mat-menu>
   </mat-toolbar>
   <mat-sidenav-container class="example-sidenav-container">
      <mat-sidenav #snav fixedTopGap="56" fixedInViewport="false" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)" style="width: 15rem;">
         <mat-nav-list>
            <app-menu></app-menu>
         </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content style="overflow-x: hidden;">
         <div class="card bg-white rounded" style="min-width: 100%;min-height: 94%;">
            <router-outlet></router-outlet>
         </div>  
         <app-admfooter></app-admfooter> 
      </mat-sidenav-content>
   </mat-sidenav-container>
</div>
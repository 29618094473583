<div class="container">
   <div class="row justify-content-center mt-4 mb-3">
      <div class="col-md-6">
            <mat-form-field appearance="" style="width: 90%;">
               <mat-label>CARI BERITA BERDASARKAN JUDUL</mat-label>
               <input matInput [(ngModel)]="carijudul">
            </mat-form-field>
            <button mat-mini-fab color="basic" style="background-color: #01347c;" aria-label="" (click)="cari()">
               <mat-icon style="color: white;">search</mat-icon>
            </button>
     </div>
   </div>
   <div class="mt-5 px-3">
      <div class="row">
         <div class="col-md-3" *ngFor="let brt of listBerita| paginate: { id: 'berita-page',
                                                                           itemsPerPage: 8,
                                                                           currentPage: page,
                                                                           totalItems: totalRecords }">
            <div class="card my-3" routerLink="/detail-berita/{{brt.id}}" style="cursor: pointer;">
               <img class="card-img-top" [attr.src]="baseUrlImg+brt.dokumentasi" alt="Foto Kegiatan" style="height: 200px;">
               <div class="card-body" style="padding-bottom: 5px !important;">
                  <p class="card-title judul">{{brt.judul | uppercase}}</p>
                  <p class="card-text deskripsi">{{brt.isiberita | removetag | removenbsp}}</p>
               </div>
               <div class="card-footer mt-2" style="padding: 5px 20px 5px;">
                  <p class="float-right my-auto" style="font-weight: 200;">{{brt.createdat}}</p>
               </div> 
            </div>
         </div>
      </div>
   </div>
   <div class="row justify-content-center mt-3 mb-4" style="margin-left: -70px !important;">
      <pagination-controls  id="berita-page"
                      (pageChange)="page = ($event)"
                      directionLinks="true"
                      autoHide="true"
                      responsive="true"
                      previousLabel="Sebelumnya"
                      nextLabel="Berikutnya"
                      screenReaderPaginationLabel="Pagination"
                      screenReaderPageLabel="page"
                      screenReaderCurrentLabel="You're on page">
      </pagination-controls>
   </div>
</div>
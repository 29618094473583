<mat-dialog-content>
  <div class="row">
    <div class="col-sm-12 text-center">
      <mat-icon aria-hidden="false" aria-label="Example home icon" style="font-size: 170px;height: 0px;width: 0px;display: inline;" >{{icon}}</mat-icon>
      <h2 class="text-center">{{data.title}}</h2>
      <p>{{data.message}}</p>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button type="button" color="warn" value="Cancel"  [mat-dialog-close]="false">BATAL </button>
  <button mat-raised-button color="primary" type="button" value="Confirm" [mat-dialog-close]="true">YA</button>
</mat-dialog-actions>
import { CarouselModel } from './../../model/carousel-model';
import { CarouselInterface } from '../../model/carousel-interface';
import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { CarouselService } from './carousel.service';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
   selector: 'app-admcarousel',
   templateUrl: './admcarousel.component.html',
   styleUrls: ['./admcarousel.component.css']
})
export class AdmcarouselComponent implements OnInit {
  
   selectedCarousel:CarouselInterface;
   selectedValue: '';
   urlCarImg: String = './assets/uploadimages/carousel-img/default-img-upload.jpg';
   imgCarLabel = 'default-img-upload.jpg';
   fileUpload:File = null;

   isiJudul = '';
   isiDeskripsi ='';
   isiFoto ='';
   carNumbers:CarouselModel[];
   tampilPerdana:boolean = false;

   form={
      id:'',
      judul:'',
      deskripsi:'',
   }
   image:File

   constructor(
      private service:CarouselService,
      private alert:Alert,
      private loading:Loading,
      private dialog:MatDialog
      ) { }

   ngOnInit(): void {
      this.getList()
   }

   getList(){
      this.loading.show()
      this.service.getCarousel().subscribe(
         listCarousels=>{
            this.loading.hide()
            this.carNumbers=listCarousels;
         },
         err=>{
            this.loading.hide()
            this.alert.error('Gagal menampilkan Carousel')
            console.log(err)
         }
      )
   }
   
   getDetailCarousel(id: any) {
      this.loading.show()
      this.selectedValue = id;
      this.tampilPerdana = false;
      this.service.getSelectedCarousel(id).subscribe(
         data=>{
            this.loading.hide()
            this.selectedCarousel = data; 
            this.form.judul = this.selectedCarousel.judulcar;
            this.form.deskripsi = this.selectedCarousel.deskripsicar;
            this.imgCarLabel = this.selectedCarousel.fotocar;
            this.form.id = this.selectedCarousel.id;
            this.urlCarImg = environment.baseApi+'resources/carousel/'+this.selectedCarousel.fotocar
         }
      )
   }

   postCarData(){
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'Anda Yakin Akan Merubah Data Ini?'
         }
      });
      confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.loading.show()
            let fd = new FormData();
            fd.append("id", this.form.id)
            fd.append("judul", this.form.judul);
            fd.append("deskripsi", this.form.deskripsi);
            fd.append("filefoto", this.image);
            fd.append("oldFile", this.imgCarLabel);
            this.service.updateCarousel(fd).subscribe(
               res=>{
                  this.loading.hide()
                  this.alert.success("Berhasil Menyimpan Data")
                  location.reload()
               },
               err=>{
                  this.loading.hide();
                  this.alert.error('Gagal Menyimpan Data')
                  console.log(err)
               }
            )
         }
      });
   }

   selectFile(e) {
      if(e.target.files) {
         let reader = new FileReader();
         reader.readAsDataURL(e.target.files[0]);
         reader.onload = (event:any) => {
            this.urlCarImg = event.target.result;
            this.tampilPerdana = true;
         }
         this.image = e.target.files[0];
      }
   }

}

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ClientService } from 'src/app/clientpages/client.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pdfview-dialog',
  templateUrl: './pdfview-dialog.component.html',
  styleUrls: ['./pdfview-dialog.component.css']
})
export class PdfviewDialogComponent implements OnInit {

  srcURL: SafeResourceUrl;
  @Input() fileToView: any;
  @Input() filename: any;
  constructor(public service: ClientService, private sanitizer: DomSanitizer) {}

  // pdfURL = '/assets/test-file.pdf';

  ngOnInit(): void {
    // console.log(this.fileToView);
    // console.log(this.filename);
    const fileBaseURL = environment.baseApi
    const url = fileBaseURL + 'resources/file/laporan/peraturan/' + this.filename;

    // Sanitize the URL to make it safe:
    this.srcURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

export class UserRegisterModel {
    id: Number;
    nama: string;
    password: string;
    bidang: string;
    subbidang: string;
    userrole: string;
    isactive: Number;
    createdat: string;
    updatedat: string;
    is_lock :string;
    // constructor(id, nama, password, bidang, subbidang, roleid, isactive){
    //     this.id = id;
    //     this.nama = nama;
    //     this.password = password;
    //     this.bidang = bidang;
    //     this.subbidang = subbidang;
    //     this.roleid = roleid;
    //     this.isactive = isactive;
    // }
}
<div class="dialog-overlay"></div>

<div class="card animate fadeUp dialog-card">
   <div class="card-header d-flex justify-content-between align-items-center py-0">
         <p class="mb-0"><strong>{{filename}}</strong></p>
         <button mat-icon-button color="warn" (click)="this.service.showPdfFile=false"><mat-icon>close</mat-icon></button>
   </div>
   <div class="card-body">
      <!-- <ngx-extended-pdf-viewer
        [src]="fileToView"
        [useBrowserLocale]="true"
        [showToolbar]="true"
        [showSidebarButton]="true"
        [showZoomButtons]="true"
        [showDownloadButton]="true"
        [showPagingButtons]="true"
        [showPrintButton]="true"
        [textLayer]="false"
        [showFindButton]="false"
        [showDrawEditor]="false"
        [showTextEditor]="false"
        [showPresentationModeButton]="false"
        [showOpenFileButton]="false"
        [showSecondaryToolbarButton]="false"
        [showRotateButton]="false"
        [showHandToolButton]="false"
        [showScrollingButton]="false"
        [showSpreadButton]="false"
        [showPropertiesButton]="false"
      ></ngx-extended-pdf-viewer> -->

      <iframe [src]="srcURL" frameborder="0" class="w-100" style="height: 100%;"></iframe>
   </div>
</div>

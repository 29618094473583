import { AuthService } from './../../../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cp-header',
  templateUrl: './cp-header.component.html',
  styleUrls: ['./cp-header.component.css']
})
export class CpHeaderComponent implements OnInit {

  constructor(public authService:AuthService,public router:Router) { }

  ngOnInit(): void {
  }

  toDashboard(){
    let url:String

    switch (this.authService.getUserRole()) {
      case "Admin":
        url = "/adminpages/dashboard"
        break;
      case "Kontributor":
        url = "/kontributor/dashboard"
        break;
      default:
        url = '/'
        break;
    }
    // return console.log(url);
    this.router.navigate([url]);
  }

}

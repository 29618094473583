import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-info-sertamerta',
  templateUrl: './info-sertamerta.component.html',
  styleUrls: ['./info-sertamerta.component.css']
})
export class InfoSertamertaComponent implements OnInit {

  tabTitle = "DPTR-DIY | PPID-sertamerta";
  baseFileUrl = environment.baseApi + 'resources/file/ppid/sertamerta/'
  data 

  constructor(
    private titleService: Title,
    private alert:Alert,
    private loading:Loading,
    private service:ClientService
  ) { }

  ngOnInit(): void {
    let res
    this.titleService.setTitle(this.tabTitle)
    this.loading.show()
    this.service.sertamerta().subscribe(
      response=>{
        this.loading.hide()
        res= response
        this.data = res.data
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Informasi')
        console.log(err)
      }
    )
  }

}

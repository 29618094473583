import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Decode } from 'src/app/shared/decode-token';

@Injectable({
  providedIn: 'root'
})
export class VisimisiService {

  constructor(private http:HttpClient,private jwt:Decode) { }

  getvisimisi(){
    let data = this.http.get(environment.baseApi+"profil/visimisi",this.jwt.httpHeder())
    return data
  }

  updateVisiMisi(param){
    return this.http.post(environment.baseApi+"profil/visimisi",$.param(param),this.jwt.httpHeder())
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminpagesComponent } from '../adminpages/adminpages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InputBeritaComponent } from './input-berita/input-berita.component';
import { KontributorGuard } from './kontributor.guard';
  
const routes: Routes = [
    {
        path : '',
        component : AdminpagesComponent,
        canActivate: [KontributorGuard],
        children:[
            {
                path:'dashboard',
                component:DashboardComponent
            },
            {
                path:'input-berita',
                component:InputBeritaComponent
            }
        ]
    },
];
  
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KontributorRoutingModule { }
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Decode } from 'src/app/shared/decode-token';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SotkService {

  constructor(private http:HttpClient,private jwt:Decode) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.jwt.token().nama+':'+this.jwt.token().userrole),
      'USR-KEY':this.jwt.token().key,
      'enctype':'multipart/form-data'
    })
  };

  getSotk(){
    return this.http.get(environment.baseApi+"profil/sotk",this.jwt.httpHeder())
  }

  updateSotk(param){
    return this.http.post(environment.baseApi+"profil/sotk",param,this.httpOptions)
  }
}

import { Injectable } from '@angular/core';
import { Decode } from 'src/app/shared/decode-token';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BeritaService {

  constructor(private jwt:Decode,private res:HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.jwt.token().nama+':'+this.jwt.token().userrole),
      'USR-KEY':this.jwt.token().key,
      'enctype':'multipart/form-data'
    })
  };

  getBerita(){
    const serverURL = environment.baseApi+'berita/admin_berita';
    return this.res.get(serverURL,this.jwt.httpHeder());
  }

  updateBerita(data){
    const serverURL = environment.baseApi+'berita/save_admin';
    return this.res.post(serverURL,data,this.httpOptions);
  }

  delete(id){
    const serverURL = environment.baseApi+'berita/admin_delete';
    return this.res.post(serverURL,$.param({id:id}),this.jwt.httpHeder());
  }

}

<!-- <p>profilpejabat works!</p> -->
<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header h5">
                    Daftar Pejabat Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta
                </div>
                <div class="card-body">
                    <div *ngFor="let dp of daftarPejabat">
                        <div class="row d-flex flex-row align-items-center mb-5">
                            <div class="circular">
                                <img src="./assets/image/pejabat/{{dp.foto}}" class="img-thumbnail" style="border: none;" alt="pejabat">
                            </div>
                            <div class="ml-3" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                                <p class="pejabat-desc" style="font-weight: bold;">{{dp.nama_jabatan}}</p>
                                <p class="pejabat-desc">{{dp.nama}}</p>
                                <p class="pejabat-desc">{{dp.panggol}}</p>
                                <p>{{dp.pendidikan}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserRegisterModel } from './../model/user-register-model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Decode } from '../shared/decode-token';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

   constructor(private httpClient:HttpClient, public snackBar:MatSnackBar,private jwt:Decode) { }

   notifConfig: MatSnackBarConfig = {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
   }

   addNewUser(userBody){
      const serverURL = environment.baseApi+'auth/register';
      return this.httpClient.post(serverURL,$.param(userBody),this.jwt.httpHeder());
   }

   updateUser(userBody){
      const serverURL = environment.baseApi+'auth/update_user';
      return this.httpClient.post(serverURL,$.param(userBody),this.jwt.httpHeder());
   }
   
   deleteUser(userBody){
      const serverURL = environment.baseApi+'auth/delete_user';
      return this.httpClient.post(serverURL,$.param(userBody),this.jwt.httpHeder());
   }

   getAllUser():Observable<UserRegisterModel[]> {
      const serverURL = environment.baseApi+'auth/register';
      return this.httpClient.get<UserRegisterModel[]>(serverURL,this.jwt.httpHeder());
   }


}

<!-- <p>{{dataPTK | json}}</p> -->
<div class="d-flex flex-row justify-content-center align-items-center" style="flex-wrap: wrap; column-gap: 30px;">
    <div>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Kategori</th>
                    <th *ngFor="let dt of dataPTK">{{dt.kabupaten}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="pr-2">Verifikasi (VER)</td>
                    <td *ngFor="let dt of dataPTK" style="text-align: right;">{{dt.Verifikasi}}</td>
                </tr>
                <tr>
                    <td class="pr-2">Validasi (VAL)</td>
                    <td *ngFor="let dt of dataPTK" style="text-align: right;">{{dt.Validasi}}</td>
                </tr>
                <tr>
                    <td class="pr-2">Kajian (KJN)</td>
                    <td *ngFor="let dt of dataPTK" style="text-align: right;">{{dt.Kajian}}</td>
                </tr>
                <tr>
                    <td class="pr-2">Total</td>
                    <td *ngFor="let dt of dataPTK" style="text-align: right; font-weight: 500;">{{dt.Total}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center flex-wrap first-chart">
        <div><canvas #ptkKabBarChart height="250" width="350"></canvas></div>
        <div>
            <canvas #ptkKabPieChart height="130" width="130"></canvas>
        </div>
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center flex-wrap">
        <div><canvas #ptkKatBarChart height="250" width="300"></canvas></div>
        <div style="width: 100px;">
            <!-- <canvas #ptkKatPieChart height="130" width="130"></canvas> -->
            <p class="m-0 mb-3">{{persenValVer}}% berkas terverifikasi telah diproses validasi</p>
            <p class="m-0">{{persenKjnVal}}% berkas tervalidasi masuk proses kajian</p>
        </div>
    </div>
</div>

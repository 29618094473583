import { AuthService } from './auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

  constructor(private authService: AuthService, public router:Router) {}

  
  canActivate(): boolean {
    let local = jwtDecode(localStorage.getItem("XRF-DAT"))

    if (local && localStorage.getItem("USR-Ro") == "Admin") {
     return true
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}

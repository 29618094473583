import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(private authService: AuthService, public router:Router) {}
  
    canActivate(): boolean {
      if (this.authService.isLoggedIn() && this.authService.getUserRole() == 'Admin') {
        this.router.navigate(['adminpages/admdashboard']);
       return false
      } else if(this.authService.isLoggedIn() && this.authService.getUserRole() == 'Kontributor') {
        this.router.navigate(['kontributor/dashboard']);
        return false;
      }else{
        return true
      }
    }
  
}

<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header h5">
                    TUGAS DAN FUNGSI
                </div>
                <div class="card-body">
                    <div class="ql-editor" [innerHTML]="dataTupoksi | safehtml" style="text-align: justify; font-weight: 370; line-height: 1.8;"></div>
                </div>
            </div>
        </div>
    </div>
</div>

import { Router } from '@angular/router';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Decode } from '../shared/decode-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa('public:public'),
      'USR-KEY':'dispertaru'
    })
  };

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(private httpClient:HttpClient, private router:Router,public jwt:Decode) {
  }  

  dataToken = this.jwt.token()
  
  public userlogin(username, password) {
    let data = {
      nama:username,
      password:password
    }
    return this.httpClient.post<any>(environment.baseApi+'auth/login', $.param(data),this.httpOptions)//base url have to in back tick
    
  }

  isLoggedIn(){
    if (localStorage.getItem('XRF-DAT')) {
      return true;
    }else{
      return false
    }
  }

  getUserRole(){
    return localStorage.getItem("USR-Ro")
  }

  getUserName(){
    return localStorage.getItem("USR-Nm")
  }

  getUserLoginId(){
    let token = this.jwt.token();
    return token.id
  }

  logOut() {
    localStorage.clear();
    // location.reload();
    this.router.navigate(["/"]);
    // if (this.router.url==="/"){
    //   location.reload();
    // } else {
    //   this.router.navigate(["/"]);
    // }
  }

}

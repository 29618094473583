<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>PROFIL - SOTK</span>
</div>

<div class="card-body mat-elevation-z8">
    <div class="row">
        <div class="col-sm-6">
            <quill-editor name="sotk" id="sotk" [styles]="{height:'350px'}" spellcheck="false" [modules]="editorModules" [(ngModel)]="uraian"></quill-editor>
            <div class="my-visibility float-left">
                <button *ngIf="!existingSotk" mat-flat-button (click)="showSotk()"><mat-icon>visibility</mat-icon> Eksisting Uraian SOTK</button>
                <button *ngIf="existingSotk" mat-flat-button (click)="hideSotk()"><mat-icon>visibility_off</mat-icon> Eksisting Uraian SOTK</button>
            </div>
            <div class="mt-1 float-right">
                <button mat-flat-button color="primary" type="submit" (click)="updateDataSotk()">UPDATE</button>
            </div>               
        </div>
        <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="custom-file">
                            <input type="file" name="imageSotk" accept="image/*" class="custom-file-input" (change)="selectFile($event)" ngModel>
                            <label class="custom-file-label" for="input-img" [innerHTML]="fomImage.name"></label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="mt-3 text-center">
                            <img [src]="fomImage.url" class="img img-fluid">
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>

<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>PROFIL - Visi Misi</span>
</div>
<div class="card-body mat-elevation-z8">
    <div class="row">
        <div class="col-sm-12">
                <div class="row">
                    <quill-editor name="visimisi" id="visimisi" [(ngModel)]='visimisi' [styles]="{height:'450px'}" spellcheck="false" [modules]="editorModules"></quill-editor>
                </div>
                <div class="row mt-3 float-right">
                    <button mat-flat-button color="primary" (click) = 'updateDataVisiMisi()' >UPDATE</button>
                </div>
            <div class="my-visibility">
                <button *ngIf="!showExistingVimi" mat-flat-button (click)="showVisiMisi()"><mat-icon>visibility</mat-icon> Eksisting VisiMisi</button>
                <button *ngIf="showExistingVimi" mat-flat-button (click)="hideVisiMisi()"><mat-icon>visibility_off</mat-icon> Eksisting VisiMisi</button>
            </div>
        </div>
    </div>
</div>

import { Component, OnInit,AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { DashboardService } from './dashboard.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {

  constructor(
    private http: DashboardService,
    private alert: Alert,
    private loading: Loading,
    private dialog: MatDialog,
    private auth: AuthService

  ) { }

  title = 'dashboard';
  displayedColumns: string[] = ['no', 'penulis', 'judul', 'status','tgl_upload','aksi'];
  data ;
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  totalBerita = {
    active: '0',
    off: '0',
    pending: '0',
    total: '0',
  }
  form = {
    type: 'created',
    oldFileName: '',
    statusBerita: '',
    editor_id: this.auth.getUserLoginId(),
    isPublish: false,
    id: '',
    judul: {
      value: '',
      error: ''
    },
    isi: {
      value: '',
      error: ''
    },
    publish: {
      value: '',
      error: ''
    },
    image: {
      title: 'Default-mage',
      url: '../../../assets/image/default-img-upload.jpg',
      label: 'Default-mage'
    }
  }
  file: File;
  status;

  editorModules = {
    toolbar: [
       ['bold', 'italic', 'underline', 'strike'],
       [{list: 'ordered'}, {list: 'bullet'}],
       [{script: 'sub'}, {script: 'super'}],
       [{indent: '-1'}, {indent: '+1'}],
       [{header: [1, 2, 3, 4, 5, 6, false]}],
       [{color: []}, {background: []}],
       [{align: []}],
    ]
  }

  onSelectFile(e){
    if (e.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event: any) => {
        this.form.image.url = event.target.result;
      }
      this.form.image.url = e.target.files[0].name;
      this.file = e.target.files[0];
      this.form.image.label = e.target.files[0].name;
    }
  }

  ngOnInit(): void {
    // this.getData()
  }

  ngAfterViewInit(): void{
    // this.donut1();
    // this.donut2();
    // this.donut3();
    // this.chartLine()
    this.getData();
    this.getTotalBerita();
  }

  getTotalBerita(){
    let data;
    this.http.getTotalBerita().subscribe(
      res => {
        data = res;
        this.totalBerita.active = data.active;
        this.totalBerita.total = data.total;
        this.totalBerita.pending = data.pending;
        this.totalBerita.off = data.off;
      },
      err => {
        this.alert.error('Gagal Mendapatakan Data Total Berita');
      }
    )
  }

  getData(){
    this.loading.show();
    this.http.getBeritaPending().subscribe(
      res => {
        this.loading.hide();
        this.data = res;
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err => {
        this.loading.hide();
        this.alert.error('Gagal Mendapatakan Data');
      }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onEdit(template,data){
    const dialogConfig = new MatDialogConfig();
    dialogConfig['disableClose'] = true
    dialogConfig['maxHeight'] = '100vh'
    dialogConfig['width'] = '1300px'
    this.dialog.open(template, dialogConfig);
    this.form.type = 'updated'
    this.form.judul.value= data.judul
    this.form.isi.value = data.isiberita
    this.form.oldFileName=data.dokumentasi
    this.form.statusBerita = data.status
    this.form.id = data.id
    this.form.image.url = environment.baseApi+"resources/berita-img/"+data.dokumentasi
    this.form.image.title =data.dokumentasi
    this.form.image.label =data.dokumentasi
    this.form.publish.value = data.publish
    //slider publish
    if (data.publish == '1') {
      this.form.isPublish = true
    }else{
      this.form.isPublish = false
    }
  }

  checkPublish(){
    if (this.form.isPublish == true) {
      this.form.publish.value = '1'
      this.form.statusBerita = 'active'
    }else{
      this.form.publish.value = '0'
      this.form.statusBerita = 'pending'
    }
  }

  onUpdate(type){
    let formData = new FormData();
    let date = new Date();
    let dateTime = date.getTime();
    formData.append('fileUpload',this.file);
    formData.append('fileName',this.form.oldFileName)
    let namaFile
    // cek apakah gambar sudah tersedia
    if (this.file != null) {
      namaFile = this.file.name.substring(0, 4);
      formData.append('fileName',namaFile+dateTime)
      formData.append('fileUpload', this.file,this.file.name);
    }
    formData.append('judul',this.form.judul.value)
    formData.append('isi',this.form.isi.value)
    formData.append('typeForm',this.form.type)
    formData.append('id',this.form.id)
    formData.append('OldFileName',this.form.oldFileName)
    formData.append('status',this.form.statusBerita)
    formData.append('editor_id',this.form.editor_id)
    formData.append('publish',this.form.publish.value)
    if (type == 'archive') {
      formData.append('status',type)
    }

    this.loading.show()
    this.http.updateBerita(formData).subscribe(
      res=>{ 
        this.status = res
        this.alert.success(this.status.message)
        setTimeout(() => {
          location.reload();
        }, 200);
        this.loading.hide()
      },
      err=>{
        this.loading.hide()
        this.alert.error(err.error.message)
        console.log(err.error);
      }
    );
  }

  onDelete(id){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: 'warning', //warning,danger,success
        title: 'Warning',
        message: 'Data Yang di Hapus Akan Berstatus ditolak Pada Halaman Kontributor'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.loading.show()
        this.http.deleteBerita(id).subscribe(
          res=>{
            this.alert.success('Data Berhasil di Hapus');
            this.getData();
            this.loading.hide()
          },
          err=>{
            this.alert.success('Data Gagal di Hapus');
            console.log(err)
            this.loading.hide()
          }
        )
      }
    });
  }

  // chartLine(){
  //   this.chart = new Chart('canvas', {
  //     type: 'line',
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: 'top',
  //         },
  //         title: {
  //           display: true,
  //           text: 'Chart.js Bar Chart'
  //         }
  //       }
  //     },
  //     data: {
  //       labels: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h','i','j'],
  //       datasets: [
  //         {
  //           label: 'My First dataset',
  //           tension: 0.1,
  //           data: [243, 156, 365, 156, 156, 265, 356, 543],
  //           backgroundColor: 'rgba(255,0,255,0.4)',
  //           borderColor: 'rgba(255,0,255,0.4)',
  //           fill: false,
  //         },
  //         {
  //           label: 'My Second dataset',
  //           data: [243, 156, 365, 156, 156, 265, 356, 543].reverse(),
  //           backgroundColor: 'rgba(0,0,255,0.4)',
  //           borderColor: 'rgba(0,0,255,0.4)',
  //           tension: 0.1,
  //           fill: false,
  //         }
  //       ]
  //     }
  //   });
  // }

  // donut1(){
  //   var donut : Chart
  //   donut = new Chart('donut1', {
  //     type: 'doughnut',
  //     data: {
  //       labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
  //       datasets: [
  //         {
  //           label: 'Dataset 1',
  //           data: [20,30,40,50,10],
  //           backgroundColor: ['#ffcd56','#ffcd57','#ffcd58','#ffcd59','#ffcd60'],
  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: 'top',
  //         },
  //         title: {
  //           display: true,
  //           text: 'Chart.js Doughnut Chart'
  //         }
  //       }
  //     },
  //   })
  // }

  // donut2(){
  //   var donut : Chart
  //   donut = new Chart('donut2', {
  //     type: 'doughnut',
  //     data: {
  //       labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
  //       datasets: [
  //         {
  //           label: 'Dataset 1',
  //           data: [20,30,40,50,10],
  //           backgroundColor: ['#ffcd56','#ffcd57','#ffcd58','#ffcd59','#ffcd60'],
  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: 'top',
  //         },
  //         title: {
  //           display: true,
  //           text: 'Chart.js Doughnut Chart'
  //         }
  //       }
  //     },
  //   })
  // }

  // donut3(){
  //   var donut : Chart
  //   donut = new Chart('donut3', {
  //     type: 'doughnut',
  //     data: {
  //       labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
  //       datasets: [
  //         {
  //           label: 'Dataset 1',
  //           data: [20,30,40,50,10],
  //           backgroundColor: ['#ffcd56','#ffcd57','#ffcd58','#ffcd59','#ffcd60'],
  //         }
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           position: 'top',
  //         },
  //         title: {
  //           display: true,
  //           text: 'Chart.js Doughnut Chart'
  //         }
  //       }
  //     },
  //   })
  // }

}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-info-berkala',
  templateUrl: './info-berkala.component.html',
  styleUrls: ['./info-berkala.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InfoBerkalaComponent implements OnInit {

  baseFileUrl = environment.baseApi + 'resources/file/ppid/berkala/'
  data


  tabTitle = "DPTR-DIY | PPID-berkala";

  constructor(
    private titleService: Title,
    private alert:Alert,
    private loading:Loading,
    private service:ClientService
    ) { }

  ngOnInit(): void {
    let res
    this.titleService.setTitle(this.tabTitle)
    this.loading.show()
    this.service.getBerkala().subscribe(
      response=>{
        res = response
        this.data = res.data
        this.loading.hide()
        
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Data Berkala')
        console.log(err)
      }
    )
  }

}

import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {

  constructor(private sanitizer:DomSanitizer){}

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../libraries/confirm-dialog/confirm-dialog.component';
import { Loading } from '../../../shared/loading';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { Alert } from 'src/app/shared/Alert';
import { KinerjaService } from './kinerja.service';

@Component({
  selector: 'app-lap-kinerja',
  templateUrl: './lap-kinerja.component.html',
  styleUrls: ['./lap-kinerja.component.css']
})
export class LapKinerjaComponent implements AfterViewInit {

  displayedColumns: string[] = ['no', 'judul', 'keterangan','tahun','aksi'];
  data ;
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public service:KinerjaService,
    private snackBar: MatSnackBar,
    public loading: Loading,
    private alert:Alert
  ) {}

  ngAfterViewInit(): void {
    this.getData()
  }

  editorModules = {
    toolbar: [
       ['bold', 'italic', 'underline', 'strike'],
       [{'list': 'ordered'}, {'list': 'bullet'}],
       [{'script': 'sub'}, {'script': 'super'}],
       [{'indent': '-1'}, {'indent': '+1'}],
       [{'header': [1, 2, 3, 4, 5, 6, false]}],
       [{'color': []}, {'background':[]}],
       [{'align': []}],
    ]
  }

  form={
    method:"created",
    id:'',
    oldFileName:'',
    input:{
      judul:{
        value:"",
        error:""
      },
      deskripsi:{
        value:"",
        error:""
      },
      tahun:{
        value:"",
        error:""
      },
      dokumen:{
        value:"",
        url:"",
        label:"",
        error:""
      },
    }
  }
  file : File
  status 

  selectFile(e){
    if(e.target.files) {
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (event:any) => {
        this.form.input.dokumen.url = event.target.result;
      }
      this.form.input.dokumen.url = e.target.files[0].name;
      this.file = e.target.files[0];
      this.form.input.dokumen.label= e.target.files[0].name;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  submit(){
    let formData = new FormData();
    let date = new Date();
    let dateTime = date.getTime();
    formData.append('title',this.form.input.judul.value)
    formData.append('deskripsi',this.form.input.deskripsi.value)
    formData.append('method',this.form.method)
    formData.append('id',this.form.id)
    formData.append('OldFileName',this.form.oldFileName)
    formData.append('tahun',this.form.input.tahun.value)
    formData.append('category','kinerja')

    if (this.file != null) {
      let namaFile = this.file.name.substring(0, 4);
      formData.append('fileUpload', this.file,this.file.name);
      formData.append('fileName','kinerja-'+this.form.input.tahun.value+namaFile+dateTime)
    }

    this.loading.show();
    this.service.insertInformasiBerkala(formData)
    .subscribe(
      res=>{ 
        this.status = res
        this.loading.hide()
        this.alert.success('Data Berhasil di Simpan')
        setTimeout(() => {
          location.reload()
        }, 200);
      },
      err=>{
        this.alert.error('Terjadi Kesalahkan, Coba Lagi')
        this.loading.hide()
      }
    );
  }

  openDialog(template) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig['disableClose'] = true
    dialogConfig['maxHeight'] = '100vh'
    dialogConfig['width'] = '800px'
    this.dialog.open(template, dialogConfig);
  }

  openSnackBar(message) {
    this.snackBar.open(message,"close",{
      duration: 1000
    });
  }

  getData(){
    this.loading.show()
    this.service.getInformasiBerkala().subscribe(
      result=>{
        this.loading.hide()
        this.data = result
        this.dataSource = new MatTableDataSource(this.data.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menampilkan Data')
        console.log(err)
      }
    )
  }

  onEdit(data,template){
    this.form.id = data.id
    this.form.method = 'updated'
    this.form.oldFileName = data.file
    this.form.input.judul.value = data.title
    this.form.input.deskripsi.value = data.deskripsi
    this.form.input.dokumen.label = data.file
    this.form.input.tahun.value = data.data_tahun
    this.openDialog(template)
  }

  resetState(){
    let form = this.form
    this.status = ''
    form.id =''
    form.method ='created'
    form.oldFileName=''
    form.input.deskripsi.value = ''
    form.input.deskripsi.error = ''
    form.input.dokumen.value = ''
    form.input.dokumen.error = ''
    form.input.dokumen.label = ''
    form.input.dokumen.url = ''
    form.input.judul.value =''
    form.input.judul.error =''
    form.input.tahun.value =''
    form.input.tahun.error =''
  }

  onDelete(param){
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        type: 'warning', //warning,danger,success
        title: 'Warning',
        message: 'Anda Yakin Ingin Menghapus Data '+param.judul
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.service.deleteData(param).subscribe(
          res=>{
            this.status = res
            this.alert.success('Data Berhasil di Hapus')
            this.getData();
          },
          err=>{
            this.alert.error('Data Gagal di Hapus')
            console.log(err)
          }
        )
      }
    });
  }

}

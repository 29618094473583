import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import jwt_decode from 'jwt-decode';
import { Loading } from 'src/app/shared/loading';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
//   providers: [{provide: MatPaginatorIntl, useClass: CustomPaginator}]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  response

  constructor(
     private fb:FormBuilder,
     private authService:AuthService, 
     private router:Router,
     public snackBar:MatSnackBar,
     public loading:Loading
     ) { }

   ngOnInit(): void {
      
      this.loginForm = this.fb.group({
         username: ['', Validators.required],
         password: ['', Validators.required]
      })
      
   }

   postdata(loginForm2) {
      this.loading.show()
      this.authService.userlogin(loginForm2.value.username, loginForm2.value.password)
      .subscribe(res => {
         this.response = jwt_decode(res.data)
         // jika dia admin redirect ke admin
         localStorage.setItem("XRF-DAT", res.data);
         localStorage.setItem("USR-Ro", this.response.userrole);
         localStorage.setItem("USR-Nm", this.response.nama);
         setTimeout(() => {
            this.loading.hide()
            switch (this.response.userrole) {
               case 'Admin':
                  this.router.navigate(['adminpages/dashboard']);
                  break;
               case 'Kontributor':
                  this.router.navigate(['kontributor/dashboard']);
                  break;
               default:
                  location.reload()
                  break;
            }
         }, 1000);
      },
      error => {
         this.snackBar.open('Username dan Password Tidak Terdaftar',"X",{
            duration: 5000,
            panelClass:'gagal'
         });
         this.loading.hide()
      });
   }

}

import { BeritaInterface } from './../../../model/berita-interface';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';
import { environment } from 'src/environments/environment';
import { Loading } from 'src/app/shared/loading';
import { Alert } from 'src/app/shared/Alert';

@Component({
  selector: 'app-detail-berita',
  templateUrl: './detail-berita.component.html',
  styleUrls: ['./detail-berita.component.css']
})
export class DetailBeritaComponent implements OnInit {

  beritaId = 0;
  detailBerita: BeritaInterface;
  baseUrlImg = environment.baseApi+"resources/berita-img/"

  constructor(
    private service:ClientService,
    private activatedRoute:ActivatedRoute,
    private alert:Alert,
    private loading:Loading
  ) { }

  ngOnInit(): void {
    this.loading.show()
    this.activatedRoute.params.subscribe(
      data=>{
        this.loading.hide()
        this.beritaId = data.id;
      },
      err=>{
        this.alert.error("gagal Menampilkan Data")
        this.loading.hide();
        console.log(err)
      }
    )

    this.service.getDetailBerita(this.beritaId).subscribe(selectedberita=>{
      this.detailBerita = selectedberita;
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Decode } from 'src/app/shared/decode-token';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private jwt:Decode,
    private http:HttpClient,
    private auth:AuthService
  ) { }

  getTotalBerita(){
    const serverURL = environment.baseApi+'dashboard/total_berita/'+this.auth.getUserLoginId();
    return this.http.get(serverURL,this.jwt.httpHeder());
  }
}

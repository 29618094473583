import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-alamat',
  templateUrl: './alamat.component.html',
  styleUrls: ['./alamat.component.css']
})
export class AlamatComponent implements OnInit {

   tabTitle = 'DPTR-DIY | Alamat';

   constructor(private titleService: Title, registerIkon: MatIconRegistry, sanitizer: DomSanitizer) {
      registerIkon.addSvgIcon('alamat', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/address-icon.svg'));
      registerIkon.addSvgIcon('telepon', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/phone-icon.svg'));
      registerIkon.addSvgIcon('website', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/www-icon.svg'));
      registerIkon.addSvgIcon('email', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/email-at.svg'));
      registerIkon.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/twit-icon.svg'));
      registerIkon.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/insta-icon.svg'));
      registerIkon.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/address-icons/yt-icon.svg'));
   }

   ngOnInit(): void {
      this.titleService.setTitle(this.tabTitle);
   }

}

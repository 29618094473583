import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sejarahdinas',
  templateUrl: './sejarahdinas.component.html',
  styleUrls: ['./sejarahdinas.component.css']
})
export class SejarahdinasComponent implements OnInit {

   tabTitle = 'DPTR-DIY | Sejarah';
   paragraf1: string = '';
   paragraf2: string = '';
   paragraf3: string = '';
   paragraf4: string = '';
   paragraf5: string = '';
   listKadis: any;

   constructor(private titleService: Title, private http: HttpClient) { }

   ngOnInit(): void {
      this.titleService.setTitle(this.tabTitle);

      this.getListKadis().subscribe(resp => {
         console.log(resp);
         this.paragraf1 = resp.data.uraian_sejarah[0].uraian_paragraf;
         this.paragraf2 = resp.data.uraian_sejarah[1].uraian_paragraf;
         this.paragraf3 = resp.data.uraian_sejarah[2].uraian_paragraf;
         this.paragraf4 = resp.data.uraian_sejarah[3].uraian_paragraf;
         this.paragraf5 = resp.data.uraian_sejarah[4].uraian_paragraf;
         this.listKadis = resp.data.daftar_kadis;
      })
   }

   getListKadis(): any{
      const serverURL = environment.baseApi + 'profil/uraianSejarah';
      const httpOptions = {
         headers: new HttpHeaders({
           'Content-Type':  'application/x-www-form-urlencoded',
           Authorization: 'Basic ' + btoa('public:public'),
           'USR-KEY': 'dispertaru'
         }),
       };
      return this.http.get(serverURL, httpOptions);
    }

}

import jwt_decode from 'jwt-decode';
import { HttpHeaders } from '@angular/common/http';

export class Decode {

    token(): any {
        try{
          const token = localStorage.getItem("XRF-DAT");
          return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
      }
    
      httpHeder(){
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + btoa(this.token().nama+':'+this.token().userrole),
            'USR-KEY':this.token().key
          })
        };
        return httpOptions
      }

} 
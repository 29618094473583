import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { TupoksiService } from './tupoksi.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-admtupoksi',
  templateUrl: './admtupoksi.component.html',
  styleUrls: ['./admtupoksi.component.css']
})
export class AdmtupoksiComponent implements OnInit {

   id
   tupoksi
   status
   showExistingTupoksi:boolean = false;

   constructor(
      private service:TupoksiService,
      private loading:Loading,
      private alert:Alert,
      private dialog:MatDialog, 
      ) { }
   
      editorModules = {
         toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background':[]}],
            [{'align': []}],
         ]
      }

   ngOnInit(): void {
      this.getTupoksi()
   }

   getTupoksi(){
      this.service.getTupoksi().subscribe(
         res=>{
           this.status = res
           this.tupoksi = this.status.data.uraian_tupoksi;
           this.id = this.status.data.id
           this.showExistingTupoksi = true
           this.loading.hide()
         },
         err=>{
           this.alert.error('Gagal Menampilkan Data')
           console.log(err)
           this.loading.hide()
         }
      )
   }

   showTupoksi() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'Data dalam teks editor akan ditimpa dengan data Tupoksi eksistng.'
         }
     });
     confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.loading.show()
            this.getTupoksi()
         }
     });
   }

   hideTupoksi(){
      this.tupoksi = '';
      this.showExistingTupoksi = false;
   }

   updateDataTupoksi(){
      let data = {
         id: this.id,
         uraian:this.tupoksi
      }
      
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'Apakah Anda Yakin Akan Menyimpan data Ini'
         }
     });
     confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.loading.show()
            this.service.updateTupoksi(data).subscribe(
             res=>{
               this.alert.success('Data Berhasil di Simpan')
               this.loading.hide()
               location.reload()
             },
             err=>{
               this.alert.error('Gagal Menyimpan Data')
               console.log(err)
               this.loading.hide()
             }
           )
         }
     });
   }

}

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-sotk',
  templateUrl: './sotk.component.html',
  styleUrls: ['./sotk.component.css']
})
export class SotkComponent implements OnInit {

  tabTitle = 'DPTR-DIY | SOTK';
  uraianSOTK = '';
  urlImgSOTK = '';
  imgSotkFileName = '';

  deskripsi: any;
  img: string;

  constructor(
    private titleService: Title,
    private service: ClientService,
    private loading: Loading,
    private alert: Alert
    ) { }

  ngOnInit(): void {
    let res: any;
    this.titleService.setTitle(this.tabTitle);
    this.loading.show();
    this.service.getSotk().subscribe(
      dataSotk => {
        this.loading.hide();
        res = dataSotk;
        this.deskripsi = res.uraian_sotk;
        this.img = environment.baseApi + 'resources/img/' + res.gambar_sotk;
      }
    );
  }

}

import { AuthService } from 'src/app/auth/auth.service';
import { UserRegisterModel } from './../../model/user-register-model';
import { UsercrudDialogsComponent } from './../admdialogs/usercrud-dialogs/usercrud-dialogs.component';
import { AdminService } from './../admin.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from '../../shared/custom-paginator-configuration';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Alert } from 'src/app/shared/Alert';
import {MatDialog,MatDialogConfig} from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';
import { Loading } from 'src/app/shared/loading';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [{provide: MatPaginatorIntl, useClass: CustomPaginator}]
})
export class UserComponent implements OnInit,AfterViewInit {
    displayColUser:string[] = ['nama', 'bidang', 'subbidang', 'akses', 'action']
    daftarUser:UserRegisterModel[] = [];
    dataSourceUser:MatTableDataSource<UserRegisterModel>;

   @ViewChild(MatSort) tableUserSort:MatSort;
   @ViewChild(MatPaginator) tableUserpaginator:MatPaginator;
 
    constructor(
       public dialogRef:MatDialog,
       private adminService:AdminService,
       private authService:AuthService,
       public snackBar:MatSnackBar,
       private alert:Alert,
       private loading:Loading
       ) { 
    }
 
    userRoleId = this.authService.getUserRole();
    userRole:boolean;
 
    ngOnInit(): void {
       if(this.userRoleId == 'Admin') {
          this.userRole = true;
       }
    }
 
    ngAfterViewInit(): void {
       this.getData()
    }

    getData(){
       this.loading.show()
      this.adminService.getAllUser().subscribe(
         dataAllUser=>{
            this.loading.hide();
            this.daftarUser = dataAllUser;
            this.dataSourceUser = new MatTableDataSource(this.daftarUser);
            this.dataSourceUser.sort = this.tableUserSort;
            this.dataSourceUser.paginator = this.tableUserpaginator;
         },
         err=>{
            this.loading.hide();
            this.alert.error('Gagal Menampilkan Data')
            console.log(err);
         }
      )
    }
   
    openUserCrudDialog(){
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
       dialogConfig.width = '35%';
       this.dialogRef.open(UsercrudDialogsComponent, dialogConfig);
    }
 
    applyUserFilter(param:string){
       this.dataSourceUser.filter = param.trim().toLowerCase();
    }
 
    onEdit(row) {
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
       dialogConfig.width = '35%';
       dialogConfig.data = {
          id: row.id,
          nama: row.nama,
          bidang: row.bidang,
          subbidang: row.subbidang,
          email:row.email,
          phone:row.phone,         
          userrole: row.userrole,
          isactive: row.isactive,
          password:'',
       }
       this.dialogRef.open(UsercrudDialogsComponent, dialogConfig);
    }

    onDelete(id){
      const confirmDialog = this.dialogRef.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'ANda Yakin Akan Menghapus Data Ini?'
         }
       });
       confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.loading.show()
            this.adminService.deleteUser(id).subscribe(
               res=>{
                 this.alert.success('Data Berhasil di Hapus')
                 this.getData();
                 this.loading.hide()
               },
               err=>{
                 this.alert.error('Data Gagal di Hapus')
                 console.log(err)
                 this.loading.hide()
               }
            )
         }
       });
    }
}

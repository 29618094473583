import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private alert:Alert,
    private loading:Loading,
    private http:DashboardService
  ) { }

  ngOnInit(): void {
    this.getTotalBerita()
  }

  totalBerita={
    active: "0",
    off: "0",
    pending: "0",
    total: "0",
  }

  getTotalBerita(){
    let data
    this.http.getTotalBerita().subscribe(
      res =>{
        data = res
        this.totalBerita.active = data.active
        this.totalBerita.total = data.total
        this.totalBerita.pending = data.pending
        this.totalBerita.off = data.off
      },
      err=>{
        this.alert.error("Gagal Mendapatakan Data Total Berita")
      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ClientService } from '../../client.service';
// import { baseUrl } from 'src/environments/environment';

@Component({
  selector: 'app-cp-footer',
  templateUrl: './cp-footer.component.html',
  styleUrls: ['./cp-footer.component.css']
})
export class CpFooterComponent implements OnInit {
  insta: string = '';
  twit: string = '';
  youtube: string = '';

  // urlImg:String = baseUrl + './../../../../assets/image/'

  txtCopyright = '© 2021 Copyright&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta';

  constructor(
    registerIkon: MatIconRegistry, 
    sanitizer: DomSanitizer,
    private clientService: ClientService
  ) {
    registerIkon.addSvgIcon('fb', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/facebook-blue.svg'));
    registerIkon.addSvgIcon('twit', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/twitter-blue.svg'));
    registerIkon.addSvgIcon('insta', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/instagram-blue.svg'));
    registerIkon.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/linkedin-blue.svg'));
    registerIkon.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('./assets/image/youtube.svg'));
  }

  ngOnInit(): void {
    this.clientService.getSosmed().subscribe(
      resp => {
        // console.log(resp);
        this.insta = resp.instagram;
        this.twit = resp.twitter;
        this.youtube = resp.youtube;
      }
    );
  }

  gotoPtrInsta(): any{
    // window.open('https://instagram.com/dispertaru.diy?igshid=MzRlODBiNWFlZA==', '_blank');
    window.open(this.insta, '_blank');
  }
  gotoPtrFb(): any{
    // window.open('https://www.twitter.com/dispertarudiy', '_blank');
    window.open(this.twit, '_blank');
  }
  gotoYoutube(): any{
    // window.open('https://www.youtube.com/channel/UCFtGDK1-g4ZUb_tbhBNrkpw', '_blank');
    window.open(this.youtube, '_blank');
  }

}

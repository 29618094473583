import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Decode } from 'src/app/shared/decode-token';

@Injectable({
  providedIn: 'root'
})
export class TiapsaatService {

  constructor(private http:HttpClient,private jwt:Decode) { }

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this.jwt.token().nama+':'+this.jwt.token().userrole),
      'USR-KEY':this.jwt.token().key,
      'enctype':'multipart/form-data'
    })
  };

  getInformasiBerkala(){
    let data = this.http.get(environment.baseApi+"ppid/tiapsaat",this.jwt.httpHeder())
    return data
  }

  insertInformasiBerkala(param){
    return this.http.post(environment.baseApi+"ppid/tiapsaat",param,this.httpOptions)
  }

  deleteData(param){
    return this.http.delete(environment.baseApi+"ppid/ppid/tiapsaat/"+param.id,this.jwt.httpHeder())
  }
}

import { AuthService } from './../auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
   BreakpointObserver,
   Breakpoints,
   BreakpointState,
 } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-adminpages',
  templateUrl: './adminpages.component.html',
  styleUrls: ['./adminpages.component.css']
})
export class AdminpagesComponent implements OnInit {
    userRoleId
    userRole
    title
    adminLogin = this.authService.getUserName();
   constructor(public authService:AuthService, private breakpointObserver: BreakpointObserver) {
    
    }

   @ViewChild('drawer') drawer: any;
   public selectedItem : string = '';
   public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));

   ngOnInit(): void {
      if(this.userRoleId == 'Admin') {
         this.userRole = true;
      }
   }

   closeSideNav() {
      if (this.drawer._mode=='over') {
        this.drawer.close();
      }
    }

}

import { Component, OnInit } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/libraries/confirm-dialog/confirm-dialog.component';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { SotkService } from './sotk.service';
import {MatDialog} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admsotk',
  templateUrl: './admsotk.component.html',
  styleUrls: ['./admsotk.component.css']
})
export class AdmsotkComponent implements OnInit {

   id
   uraian
   image:File
   existingSotk
   fomImage={
      url:'',
      name:'',
      oldFile:'',
      method:'created'
   }
   response

   constructor(
      private service:SotkService,
      private alert:Alert,
      private loading:Loading,
      private dialog:MatDialog
   ) { }

   editorModules = {
      toolbar: [
         ['bold', 'italic', 'underline', 'strike'],
         [{'list': 'ordered'}, {'list': 'bullet'}],
         [{'script': 'sub'}, {'script': 'super'}],
         [{'indent': '-1'}, {'indent': '+1'}],
         [{'header': [1, 2, 3, 4, 5, 6, false]}],
         [{'color': []}, {'background':[]}],
         [{'align': []}],
      ]
   }

   ngOnInit(): void {
     this.getSotk()
   }

   getSotk(){
      this.loading.show()
      this.service.getSotk().subscribe(
         res=>{
            this.loading.hide()
            this.response = res
            if (this.response != null) {
               this.existingSotk = true
               this.uraian = this.response.uraian_sotk
               this.fomImage.name = this.response.gambar_sotk
               this.fomImage.url = environment.baseApi+'resources/img/'+this.response.gambar_sotk
               this.fomImage.oldFile = this.response.gambar_sotk
               this.id = this.response.id
               this.fomImage.method = 'updated'
            }
         },
         err=>{
            this.loading.hide()
            this.alert.error('Gagal Menampilkan Data')
            console.log(err)
         }
      )
   }

   showSotk() {
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'Apakah Anda Yakin Akan Menimpa Sotk?'
         }
     });
      confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.getSotk()
         }
      });
   }

   hideSotk(){
      this.existingSotk = false;
      this.uraian =''
      this.fomImage.url =''
      this.fomImage.name =''
      this.fomImage.method='created'
   }

   selectFile(e) {
      if(e.target.files) {
         let reader = new FileReader();
         reader.readAsDataURL(e.target.files[0]);
         reader.onload = (event:any) => {
            this.existingSotk=true;
            this.fomImage.url = event.target.result;
            this.fomImage.name= e.target.files[0].name;
         }
         this.image = e.target.files[0];
      }
   }

   updateDataSotk(){
      let data = new FormData()
      if (this.image == null && this.fomImage.method =='created') {
         return this.alert.error('Gambar SOTK Harus di Isi')
      }
      data.append("id",this.id)
      data.append("uraian",this.uraian)
      data.append("fileUpload",this.image)
      data.append("OldFileName",this.fomImage.oldFile)
      data.append("method",this.fomImage.method)
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
         data: {
           type: 'warning', //warning,danger,success
           title: 'Warning',
           message: 'Apakah Anda Yakin Akan Menyimpan Data Ini?'
         }
      });
      confirmDialog.afterClosed().subscribe(result => {
         if (result === true) {
            this.loading.show()
            this.service.updateSotk(data).subscribe(
               res=>{
                  this.alert.success('Data Berhasil di Simapn')
                  this.loading.hide()
               },
               err=>{
                  this.alert.error('Data Gagal di Simpan');
                  this.loading.hide()
               }
            )
         }
      });
   }

}

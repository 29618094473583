import { CarouselModel } from './../../model/carousel-model';
import { BeritaModel } from './../../model/berita-model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Loading } from 'src/app/shared/loading';
import { Alert } from 'src/app/shared/Alert';
import { ClientService } from '../client.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-beranda',
  templateUrl: './beranda.component.html',
  styleUrls: ['./beranda.component.css'],
})

export class BerandaComponent implements OnInit {
  tabTitle = 'DPTR-DIY | Beranda';
  adminLogin = 'tidak ada yang login';
  listBerita: BeritaModel[] = [];
  listCarousel: CarouselModel[] = [];
  baseUrl = environment.baseApi;
  currentDate = new Date().getFullYear();

  constructor(
    private titleService: Title,
    private service: ClientService,
    private loading: Loading,
    private alert: Alert,
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.tabTitle);
    this.getBerita();
    this.getCarausel();
  }


  getBerita(): any{
    this.loading.show();
    this.service.getTampilBerita().subscribe(
      data => {
        this.loading.hide();
        this.listBerita = data;
      },
      err => {
        this.loading.hide();
        this.alert.error('Gagal Menampilkan Berita');
      }
    );
  }

  getCarausel(): any{
    this.service.getCarousel().subscribe(
      datacar => {
        this.listCarousel = datacar;
      },
      err => {
        this.alert.error('Gagal Menampilkan Carausel');
      }
    );
  }

  gotoIntan(): any{
    window.open('http://pertanahan.jogjaprov.go.id', '_blank');
  }
  gotoSengguh(): any{
    window.open('https://sengguh.jogjaprov.go.id', '_blank');
  }
  gotoElapor(): any{
    window.open('https://lapor.jogjaprov.go.id', '_blank');
  }
  gotoJdih(): any{
    window.open('http://birohukum.jogjaprov.go.id/', '_blank');
  }
  gotoAio(): any{
    window.open('https://dispertaru.jogjaprov.go.id/aio-dptr', '_blank');
  }
  gotoCC(): any{
    window.open('https://geoportal.onemap.id/portal/apps/sites/#/pusdatindptrdiy/', '_blank');
  }

}

<div class="card-header text-primary bg-white">
    <span class="h5" mb-1>PUBLIKASI - Laporan Kinerja</span>
    <button mat-raised-button color="primary" style="float: right;" (click)="openDialog(formBerita);resetState()">Tambah <mat-icon aria-hidden="false" aria-label="new icon">add</mat-icon></button>
</div>
<div class="card-body">
    <div class="row">
        <div class="col-sm-12">
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
            </mat-form-field>
                
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>
                
                    <!-- ID Column -->
                    <ng-container matColumnDef="no">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> NO </th>
                        <td mat-cell *matCellDef="let row;let i = index"> {{i+1}} </td>
                    </ng-container>
                    
                        <!-- Progress Column -->
                    <ng-container matColumnDef="judul">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> JUDUL </th>
                        <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                    </ng-container>
                    
                        <!-- Name Column -->
                    <ng-container matColumnDef="keterangan">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> KETERANGAN </th>
                        <td mat-cell *matCellDef="let row"> {{row.deskripsi | removetag |removenbsp}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tahun">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> DATA TAHUN </th>
                        <td mat-cell *matCellDef="let row"> {{row.data_tahun}} </td>
                    </ng-container>
                    
                        <!-- Fruit Column -->
                    <ng-container matColumnDef="aksi">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> <mat-icon>settings</mat-icon> </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="primary" aria-label="Edit" (click) = "onEdit(row,formBerita)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" aria-label="Hapus" (click) = "onDelete(row)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center" colspan="5">Data Tidak Tersedia Pada Tabel Ini</td>
                    </tr>
                </table>
                <mat-paginator [pageSize]="10" [pageSizeOptions]="[10,25,50,100]"></mat-paginator>
            </div>                      
        </div>    
    </div>
</div>


<!-- dialog -->
<ng-template class="contanier" #formBerita>
    <h3 mat-dialog-title>FORM LAPORAN KINERJA</h3>
    <mat-dialog-content style="max-height: 80vh;">
        <div class="row">
            <div class="col-sm-9">
                <mat-form-field appearance="standard" style="width: 100%;"> 
                    <mat-label>Judul <small>(Harus diisi)</small></mat-label>
                    <input matInput placeholder="Masukan Judul" [(ngModel)] = "form.input.judul.value">
                    <mat-error *ngIf="form.input.judul.error">
                        {{form.input.judul.error}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-3">
                <mat-form-field appearance="standard" style="width: 100%;"> 
                    <mat-label>Data Tahun <small>(Harus diisi)</small></mat-label>
                    <input matInput placeholder="Ex 2021" [(ngModel)] = "form.input.tahun.value">
                    <mat-error *ngIf="form.input.tahun.error">
                        {{form.input.tahun.error}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-label>File/Dokumen <small>(Disarankan format .pdf)</small> </mat-label>
                <div class="input-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" required [(ngModel)]="form.input.dokumen.value" (change)="selectFile($event)" accept="application/pdf">
                        <label class="custom-file-label" [innerHTML]="form.input.dokumen.label"></label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-label>Deskripsi <small>(Jika Ada)</small></mat-label>
                <div class="justify-content-center">
                    <quill-editor [(ngModel)] = "form.input.deskripsi.value" [styles]="{height:'250px'}" spellcheck="false" [modules]="editorModules"  required></quill-editor>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-raised-button mat-dialog-close color="warn">Batal</button>
        <button mat-raised-button color="primary" (click) ="submit()">Simpan</button>
    </mat-dialog-actions>
</ng-template>

import {MatSnackBar} from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Alert {
    constructor(private snackBar: MatSnackBar) {  }

    success(message){
        this.snackBar.open(message,'X',{
            duration: 3000,
            panelClass:'berhasil'
        });
    }

    warning(message){
        this.snackBar.open(message,"X",{
            duration: 3000,
            panelClass:'berhasil'
        });
    }  

    error(message){
        this.snackBar.open(message,"X",{
            duration: 3000,
            panelClass:'gagal'
        });
    }
}
<!-- <p>satriya works!</p> -->
<div class="container mt-3">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header h5">
                    Budaya Satriya
                </div>
                <div class="card-body">
                    <img class="logoparagraph" src="./assets/image/logo-satriya-diy.jpg" style="height: 150px;" alt="lodo-satriya-diy">
                    <div>
                        <p class="paragraph">
                            Budaya Pemerintahan SATRIYA yang telah ditetapkan dengan Peraturan Gubernur Daerah Istimewa Yogyakarta Nomor 72 Tahun 2008 adalah salah bentuk komitmen Pemerintah Daerah Daerah Istimewa Yogyakarta dalam mewujudkan tata kelola pemerintahan yang baik <span style="font-style: italic;">(good governance)</span>. Landasan filosofis yang mendasari budaya pemerintahan SATRIYA   adalah <span style="font-weight: bold; font-style: italic;">”Hamemayu Hayuning Bawana”</span> yang mengandung makna melindungi, memelihara dan membina keselamatan dunia dan lebih mementingkan berkarya untuk masyarakat dari pada memenuhi ambisi pribadi.<br>
                            SATRIYA memiliki dua makna yaitu pertama SATRIYA dimaknai sebagai watak ksatriya yang memiliki sikap memegang teguh ajaran moral <span style="font-weight: bold; font-style: italic;">sawiji, greget, sengguh ora mingkuh</span> dan semangat <span style="font-weight: bold; font-style: italic;">golong gilig</span>. Makna kedua SATRIYA sebagai akronim dari <span style="font-weight: bold;">Selaras, Akal budi luhur-jati diri, Teladan-keteladanan, Rela melayani, Inovatif, Yakin percaya diri, dan Ahli profesional</span> yang dijabarkan ke dalam indikator sebagaimana diatur dalam Peraturan Gubernur Daerah Istimewa Yogyakarta Nomor 72 Tahun 2008.
                        </p>
                        <div class="d-flex">
                            <div class="paragraph2col" style="width: 47%;">
                                <p class="mb-0">
                                    Nilai-nilai budaya pemerintahan SATRIYA yang terjabarkan dalam indikator perilaku tersebut dalam aktualisasinya akan tercermin pada:
                                </p>
                                <ol type="a" style="padding-left: 17px;">
                                    <li>Pemahaman terhadap makna bekerja;</li>
                                    <li>Sikap terhadap pekerjaan atau yang dikerjakan;</li>
                                    <li>Sikap terhadap lingkungan pekerjaan;</li>
                                    <li>Sikap terhadap waktu;</li>
                                    <li>Sikap terhadap alat yang digunakan untuk bekerja;</li>
                                    <li>Etos kerja; dan</li>
                                    <li>Perilaku ketika bekerja atau mengambil keputusan.</li>
                                </ol>
                                <p class="mb-0">
                                    Pengembangan budaya pemerintahan akan memberikan manfaat baik bagi pegawai sendiri maupun bagi lingkungan kerjanya (instansinya). Manfaat budaya pemerintahan bagi instansi adalah:
                                </p>
                                <ol type="a" style="padding-left: 17px;">
                                    <li>Meningkatkan kerjasama antar individu, antar kelompok dan antar unit kerja;</li>
                                    <li>Meningkatkan koordinasi, integrasi, sinkronisasi dan keselarasan dalam organisasi;</li>
                                    <li>Memperlancar komunikasi dan hubungan kerja;</li>
                                    <li>Menumbuhkan kepemimpinan yang partisipatif;</li>
                                    <li>Mengeliminir hambatan-hambatan psikologis dan kultural; dan</li>
                                    <li>Menciptakan suasana kerja yang menyenangkan sehingga merangsang kreatifitas pegawai dan pada akhirnya meningkatkan kinerja instansi dan peningkatan pelayanan publik.</li>
                                </ol>
                            </div>
                            <div style="width: 50px;"></div>
                            <div class="paragraph2col" style="width: 53%;">
                                <p class="mb-0">
                                    Berkenaan dengan implementasi Budaya Satriya, berikut ini adalah daftar usulan kegiatan dan indikator Budaya Pemerintahan di lingkungan Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta:
                                </p>
                                <ol style="padding-left: 17px;">
                                    <li>Pembentukan Tim Zona Integritas, dengan indikator: SK Tim Zona Integritas;</li>
                                    <li>Penandatanganan Pakta Integritas, dengan indikator: persentase pegawai yang telah menandatangani pakta integritas;</li>
                                    <li>Penyusunan Rencana Aksi Zona Integritas oleh Tim Zona Integritas dan melibatkan seluruh Aparatur Sipil Negara (ASN) di lingkungan Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta, dengan ndikator: Dokumen Rencana aksi Zona Integritas;</li>
                                    <li>Gerakan 5R + 1A (Rapi, Resik, Rajin, Ringkas, Rawat, dan Asri), dengan indikator: persentase ruang perkantoran yang bersih, rapi, dan asri;</li>
                                    <li>Pembinaan rohani/pengajian, dengan indikator: persentase jumalah pegawai yang mengikuti pembinaan rohani/pengajian;</li>
                                    <li>Jumat Sehat, dengan indikator: persentase jumlah pegawai yang mengikuti kegiatan jumat sehat;</li>
                                    <li>Kegiatan Outdoor ASN Dispertaru DIY, dengan indikator: persentase pegawai yang berpartisipasi kegiatan outdoor;</li>
                                    <li>Pembinaan arsip, dengan indikator: nilai indeks pengelolaan arsip;</li>
                                    <li>Gerakan Pegawai Profesional Mandiri, dengan indikator: Nilai Indeks Kepuasan Masyarakat; dan</li>
                                    <li>Penyampaian bantuan pada BAZNAS/panti asuhan, dengan indikator: Frekuensi bantuan ke BAZNAS/panti asuhan.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>

import { DetailBeritaComponent } from './clientpages/publikasi/detail-berita/detail-berita.component';
import { LapKeuanganComponent } from './clientpages/publikasi/lap-keuangan/lap-keuangan.component';
import { LapKinerjaComponent } from './clientpages/publikasi/lap-kinerja/lap-kinerja.component';
import { BeritaComponent } from './clientpages/publikasi/berita/berita.component';
import { RenstraComponent } from './clientpages/publikasi/renstra/renstra.component';
import { InfoTiapsaatComponent } from './clientpages/ppid/info-tiapsaat/info-tiapsaat.component';
import { InfoSertamertaComponent } from './clientpages/ppid/info-sertamerta/info-sertamerta.component';
import { InfoBerkalaComponent } from './clientpages/ppid/info-berkala/info-berkala.component';
import { SotkComponent } from './clientpages/profil/sotk/sotk.component';
import { TupoksiComponent } from './clientpages/profil/tupoksi/tupoksi.component';
import { VisimisiComponent } from './clientpages/profil/visimisi/visimisi.component';
import { BerandaComponent } from './clientpages/beranda/beranda.component';
import { ClientpagesComponent } from './clientpages/clientpages.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from './libraries/page404/page404.component';
import { ProfilpejabatComponent } from './clientpages/profil/profilpejabat/profilpejabat.component';
import { AlamatComponent } from './clientpages/profil/alamat/alamat.component';
import { LhkpnComponent } from './clientpages/profil/lhkpn/lhkpn.component';
import { SatriyaComponent } from './clientpages/profil/satriya/satriya.component';
import { SejarahdinasComponent } from './clientpages/profil/sejarahdinas/sejarahdinas.component';
import { PeraturanComponent } from './clientpages/publikasi/peraturan/peraturan.component';

const routes: Routes = [
   {
      path: '',
      component: ClientpagesComponent,
      children: [
         {
            path: '',
            component: BerandaComponent
         },
         {
            path: 'visimisi',
            component: VisimisiComponent
         },
         {
            path: 'tupoksi',
            component: TupoksiComponent
         },
         {
            path: 'sotk',
            component: SotkComponent
         },
         {
            path: 'profilpejabat',
            component: ProfilpejabatComponent
         },
         {
            path: 'lhkpn',
            component: LhkpnComponent
         },
         {
            path: 'alamat',
            component: AlamatComponent
         },
         {
            path: 'sejarahdinas',
            component: SejarahdinasComponent
         },
         {
            path: 'satriya',
            component: SatriyaComponent
         },
         {
            path: 'info-berkala',
            component: InfoBerkalaComponent
         },
         {
            path: 'info-sertamerta',
            component: InfoSertamertaComponent
         },
         {
            path: 'info-tiapsaat',
            component: InfoTiapsaatComponent
         },
         {
            path: 'renstra',
            component: RenstraComponent
         },
         {
            path: 'berita',
            component: BeritaComponent
         },
         {
            path: 'detail-berita/:id',
            component: DetailBeritaComponent
         },
         {
            path: 'lap-kinerja',
            component: LapKinerjaComponent
         },
         {
            path: 'lap-keuangan',
            component: LapKeuanganComponent
         },
         {
            path: 'peraturan',
            component: PeraturanComponent
         }
      ]
   },
   {
      path: 'adminpages',
      loadChildren: () => import('./adminpages/adminpages.module').then(m => m.AdminpagesModule),
      // canActivate: [AuthguardGuard]
   },
   {
      path: 'kontributor',
      loadChildren: () => import('./kontributor/kontributor.module').then(m => m.KontributorModule),
   },
   { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
   {path: '404', component: Page404Component},
   {path: '**', redirectTo: '/404'}
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})

export class AppRoutingModule { }

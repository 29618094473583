import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admfooter',
  templateUrl: './admfooter.component.html',
  styleUrls: ['./admfooter.component.css']
})
export class AdmfooterComponent implements OnInit {

  txtCopyright = "© 2021 Copyright&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Dinas Pertanahan dan Tata Ruang Daerah Istimewa Yogyakarta";
  
  constructor() { }

  ngOnInit(): void {
  }

}

<!-- <p>usercrud-dialogs works!</p> -->
<div class="mb-4 text-center bg-light" *ngIf="!isExisted">
        <h2 mat-dialog-title>FORM TAMBAH USER </h2>
</div>
<div class="mb-4 text-center bg-light" *ngIf="isExisted">
        <h2 mat-dialog-title>FORM EDIT USER</h2>
</div>
<form [formGroup]="myRegisterForm" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-6">
                <input type="hidden" formControlName="id">
                <mat-form-field appearance="" spellcheck="false" style="width: 100%;">
                    <mat-label>Nama User</mat-label>
                    <input matInput type="text" formControlName="nama" cdkFocusInitial>
                    <mat-error>Nama wajib diisi!</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="" style="width: 100%;">
                    <mat-label>Bidang</mat-label>
                    <mat-select [(value)]="selectedBidang" formControlName="bidang" (selectionChange)="onBidangChange($event.value)">
                        <mat-option value="Sekretariat">Sekretariat</mat-option>
                        <mat-option value="PPP">PPP</mat-option>
                        <mat-option value="PPPPP">PPPPP</mat-option>
                        <mat-option value="TURBIN">TURBIN</mat-option>
                        <mat-option value="LAKWAS">LAKWAS</mat-option>
                    </mat-select>
                    <mat-error>Bidang wajib dipilih!</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="!isExisted">
            <div *ngIf="selectedBidang" class="mb-4">
                <mat-radio-group color="primary" formControlName="subBidang" (change)="onSubbidangChange($event.value)">
                    <div class="mx-3">
                        <div class="row mt-3 pt-1 px-1" style="border: 1px solid #E0E0E0; border-radius: 4pt; justify-content: space-between;">
                            <div *ngFor="let subbid of listsubbidang">
                                <div class="col-md-12 px-2 mt-1">
                                    <mat-radio-button value={{subbid}}>{{subbid}}</mat-radio-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-radio-group>
                <mat-error class="pl-2 mt-1" *ngIf="myForm.subBidang.errors?.required" style="font-size: x-small;">Subbidang wajib dipilih!</mat-error>
            </div>
        </div>
        <div *ngIf="isExisted" class="mb-4">
            <mat-radio-group color="primary" formControlName="subBidang" (change)="onSubbidangChange($event.value)">
                <div class="mx-3">
                    <div class="row mt-3 pt-1 px-1" style="border: 1px solid #E0E0E0; border-radius: 4pt; justify-content: space-between;">
                        <div *ngFor="let subbid of listsubbidang">
                            <div class="col-md-12 px-2 mt-1">
                                <mat-radio-button value={{subbid}}>{{subbid}}</mat-radio-button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
            <mat-error class="pl-2 mt-1" *ngIf="myForm.subBidang.errors?.required" style="font-size: x-small;">Subbidang wajib dipilih!</mat-error>
        </div>
        <div class="row mt-4">
            <div class="col-md-6">
                <mat-form-field appearance="" style="width: 100%;">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-error *ngIf="myForm.email.errors?.required">Email Harus diisi!</mat-error>
                    <mat-error *ngIf="myForm.email.errors?.email">Email Harus Sesuai</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="" style="width: 100%;">
                    <mat-label>Phone</mat-label>
                    <input matInput type="text" formControlName="phone">
                    <mat-error *ngIf="myForm.phone.errors?.required">Phone Harus diisi!</mat-error> 
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="" style="width: 100%;">
                    <mat-label>Password</mat-label>
                    <input matInput type="text" formControlName="password">
                    <mat-error *ngIf="myForm.password.errors?.required">Password wajib diisi!</mat-error>
                    <mat-error *ngIf="myForm.password.errors?.minlength">Password minimal 6 karakter!</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="" style="width: 100%;">
                    <mat-label>Konfirmasi Password</mat-label>
                    <input matInput type="text" formControlName="confirmPassword">
                    <mat-error *ngIf="myForm.confirmPassword.errors?.required">Password wajib diisi!</mat-error>
                    <mat-error *ngIf="myForm.confirmPassword.errors?.matchingPassword">Konfirmasi Password Wajib = Password</mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- {{selectedBidang}} -->
        <mat-divider class="mt-3 mb-1"></mat-divider>
        
        <div class="row" style="height: 60px;">
            <div class="col-md-7 ml-3">
                <mat-radio-group color="primary" formControlName="userRole" style="width: 100%;">
                    <div class="row mt-3 pt-2" style="border: 1px solid #E0E0E0; border-radius: 4pt; justify-content: space-evenly;">
                        <mat-radio-button value="Admin">Admin</mat-radio-button>
                        <mat-radio-button value="Kontributor">Kontributor</mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
            <div class="col-md-4 mr-3 align-self-center pt-3">
                <div class="row d-flex float-right">
                    <mat-slide-toggle formControlName="isActive" (change)="onActiveSliderChange($event)" color="primary">AKTIF</mat-slide-toggle>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-divider class="mt-3"></mat-divider>

    <mat-dialog-actions align="end" class="mt-2">
        <button mat-button color="warn" mat-dialog-close>Batal</button>
        <button mat-button color="primary" type="submit" [disabled]="myRegisterForm.invalid">Simpan</button>
    </mat-dialog-actions>
</form>
export class Loading {

    show(){
        document.getElementById("top").innerHTML = '<div id="loading"></div>'
    }

    hide(){
        document.getElementById("top").innerHTML = ''
    }
}

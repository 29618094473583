import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removetag'
})
export class RemovetagPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(text: string, ...usefulTags: any[]): string {
    return usefulTags.length > 0
      ? text.replace(new RegExp(`<(?!\/?(${usefulTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
      : text.replace(/<(?:.|\s)*?>/g, '');
      // : text.replace(/<(?:.|\s)*?>\&nbsp;/g, '');
      // : text.replace(/<(?:.|\s)*?>\u00a0/g, '');
  }

}

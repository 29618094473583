import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Alert } from 'src/app/shared/Alert';
import { Loading } from 'src/app/shared/loading';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client.service';

@Component({
  selector: 'app-lap-kinerja',
  templateUrl: './lap-kinerja.component.html',
  styleUrls: ['./lap-kinerja.component.css']
})
export class LapKinerjaComponent implements OnInit {

  baseFileUrl = environment.baseApi + 'resources/file/laporan/kinerja/'

  data 

  tabTitle = "DPTR-DIY | Pub-LapKin";

  constructor(
    private titleService: Title,
    private service:ClientService,
    private loading:Loading,
    private alert:Alert
    ) { }

  ngOnInit(): void {
    let res
    this.loading.show()
    this.titleService.setTitle(this.tabTitle)
    this.service.laporan('kinerja').subscribe(
      response=>{
        this.loading.hide()
        res = response
        this.data =res.data
      },
      err=>{
        this.loading.hide()
        this.alert.error('Gagal Menmpilkan Data')
        console.log(err)
      }
    )
  }

}
